import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";

const useStyles = makeStyles((theme) =>
  createStyles({
    footerNotAppBar: {
      maxWidth: "1080px",
      margin: "auto",
      width: "100%",
      paddingBottom: "12px",
      paddingTop: "12px",
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      [theme.breakpoints.down("sm")]: {
        flexDirection: "column",
        alignItems: "center",
      },
    },
    footerLogoMobileWrapper: {
      display: "none",
      [theme.breakpoints.down("sm")]: {
        display: "inline-block",
        maxWidth: "78px",
      },
    },
    footerLogoDesktopWrapper: {
      display: "inline-block",
      [theme.breakpoints.down("sm")]: {
        display: "none",
      },
    },
    footerText: {
      fontWeight: "200",
      [theme.breakpoints.down("sm")]: {
        textAlign: "center",
        fontSize: "12px",
        paddingRight: "4px",
        paddingLeft: "4px",
        marginTop: "15px",
      },
    },
  }),
);

const Footer = () => {
  const classes = useStyles();

  return (
    <div
      style={{
        backgroundColor: "#223647",
        height: "auto",
        color: "white",
        fontFamily: "BatonTurbo-Book",
        fontSize: "12px",
        fontWeight: "200",
      }}
    >
      <div className={classes.footerNotAppBar}>
        <div className={classes.footerLogoMobileWrapper}>
          <img
            src="/img/CP-Logo.svg"
            alt="Commercial Publishing"
            className={classes.footerCPlogo}
          />
        </div>
        <div className={classes.footerText}>
          Reportings are created by the Commercial Publishing team. Please click
          <span>
            <a
              href="mailto:tech-cp@20minuten.ch?subject=Questions%20%26%20Suggestions%20(Reporting-Tool)"
              style={{
                color: "white",
                textDecoration: "underline",
                marginLeft: "4px",
                marginRight: "0px",
              }}
              target="_blank"
              rel="noopener noreferrer"
            >
              here
            </a>
          </span>
          <span>{` for questions or suggestions.`}</span>
        </div>
        <div className={classes.footerLogoDesktopWrapper}>
          <img
            src="/img/CP-Logo.svg"
            alt="Commercial Pulishing"
            className={classes.footerCPlogo}
          />
        </div>
      </div>
    </div>
  );
};

export default Footer;
