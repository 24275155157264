import { Grid } from "@mui/material";
import BigNumber from "../BigNumber";

const color = "#5587b0";

const Summary = ({ stories }) => {
  if (!stories || stories.length === 0) {
    return null;
  }

  let impressions = 0;
  let reach = 0;
  let outboundClicks = 0;
  let postClicks = 0;
  let interactions = 0;
  stories.forEach((s) => {
    if (s.impressions) {
      impressions += s.impressions;
    }
    if (s.reach) {
      reach += s.reach;
    }
    if (s.outboundClicks) {
      outboundClicks += s.outboundClicks;
    }
    if (s.postClicks) {
      postClicks += s.postClicks;
    }
    if (s.interactions) {
      interactions += s.interactions;
    }
  });

  return (
    <Grid
      container
      spacing={2}
      sx={{
        paddingTop: 0,
        paddingBottom: {
          xs: "10px",
          lg: "24px",
        },
        fontFamily: "BatonTurbo-Book",
      }}
    >
      <Grid item xs={12} sm={12} md={12}>
        <Grid container spacing={2}>
          <Grid
            item
            md={12}
            sm={12}
            xs={12}
            sx={{
              fontSize: {
                xs: "14px",
                md: "18px",
              },
              color: "#262627",
              lineHeight: "1.11",
              marginBottom: "6px",
              marginTop: "2em",
            }}
          >
            Social Media
          </Grid>
          <BigNumber
            title="Impressions"
            description="Amount of displays of a teaser on a media site"
            value={impressions}
            color={color}
          />
          <BigNumber
            title="Reach"
            description="Amount of displays of a teaser on a media site"
            value={reach}
            color={color}
          />
          <BigNumber
            title="Interaction Rate"
            description="Level of communication between an audience member and a brand's social profile"
            value={(interactions / reach) * 100}
            unit="%"
            decimals={3}
            color={color}
          />
          <BigNumber
            title="Interactions"
            description="Amount of user engagement"
            value={interactions}
            color={color}
          />
          <BigNumber
            title="Outbound Clicks"
            description="Amount of outbound clicks on hyperlinks, pictures and logos (excl. CTA clicks)"
            value={outboundClicks}
            color={color}
          />
          {Boolean(outboundClicks || postClicks) && Boolean(impressions) && (
            <BigNumber
              title="CTR"
              description="Average click through rate"
              value={((outboundClicks || postClicks) / impressions) * 100}
              unit="%"
              decimals={3}
              color={color}
            />
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Summary;
