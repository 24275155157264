import { Box } from "@mui/material";
import { ResponsiveBar } from "@nivo/bar";
import moment from "moment";
import numeral from "numeral";
import colorScheme from "./colorScheme";

const tooltipConstructor = (data) => (
  <div style={{ display: "flex", flexDirection: "row" }}>
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        border: `2px solid ${data.color}`,
        padding: "10px",
      }}
    >
      <div>Story ID: {data.id}</div>
      <div>Pageviews: {data.value}</div>
      <div>{moment(data.indexValue).format("LL")}</div>
    </div>
  </div>
);

const BarStackedTS = ({ data }) => {
  if (!data) {
    return null;
  }

  return (
    <Box
      sx={{
        height: {
          xs: "420px",
          sm: "500px",
          lg: "600px",
        },
      }}
    >
      <ResponsiveBar
        data={
          Array.isArray(data.data)
            ? data.data.sort((a, b) => new Date(a.date) - new Date(b.date))
            : null
        }
        indexBy="date"
        keys={data.storyIds}
        colors={colorScheme}
        margin={{ top: 50, right: 0, bottom: 150, left: 50 }}
        yScale={{
          type: "linear",
          min: "auto",
          max: "auto",
          stacked: true,
          reverse: false,
        }}
        axisBottom={{
          tickRotation: -30,
          format: (v) => moment(v).format("ll"),
        }}
        xFormat="time:%Y-%m-%d"
        xScale={{
          type: "time",
          format: "%Y-%m-%d",
        }}
        labelSkipWidth={12}
        axisLeft={{
          format: (v) => numeral(v).format(),
        }}
        legends={[
          {
            anchor: "bottom-left",
            dataFrom: "keys",
            direction: "row",
            justify: false,
            translateX: 0,
            translateY: 70,
            itemsSpacing: 0,
            itemDirection: "left-to-right",
            itemWidth: 80,
            itemHeight: 20,
            itemOpacity: 0.75,
            symbolSize: 12,
            symbolShape: "square",
            symbolBorderColor: "rgba(0, 0, 0, .5)",
            itemTextColor: "black",
          },
        ]}
        tooltip={tooltipConstructor}
      />
    </Box>
  );
};

export default BarStackedTS;
