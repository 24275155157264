import { Paper, Grid, Box, Button } from "@mui/material";

const LoginNope = () => (
  <div
    style={{
      padding: "15px",
      maxWidth: "694px",
      margin: "auto",
      display: "flex",
      color: "#ffffff",
      fontSize: "22px",
    }}
  >
    <Grid container spacing={2}>
      <Grid item xs={12} sm={12} md={12}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              height: {
                xs: "68px",
                md: "93.6px",
              },
              width: {
                xs: "68px",
                md: "93.6px",
              },
              marginRight: "40px",
            }}
          >
            <Box
              component="img"
              src="/img/20min_de_vector_negative.svg"
              alt="Tamedia Logo"
              sx={{
                height: {
                  xs: "68px",
                  md: "93.6px",
                },
                width: {
                  xs: "68px",
                  md: "93.6px",
                },
              }}
            />
          </Box>
          <Box
            sx={{
              height: {
                xs: "68px",
                md: "93.6px",
              },
              width: {
                xs: "68px",
                md: "93.6px",
              },
              marginRight: 0,
            }}
          >
            <Box
              component="img"
              src="/img/Tamedia-Logo.svg"
              alt="Tamedia Logo"
              sx={{
                height: {
                  xs: "68px",
                  md: "93.6px",
                },
                width: {
                  xs: "68px",
                  md: "93.6px",
                },
              }}
            />
          </Box>
        </div>
      </Grid>
      <Grid item xs={12} sm={12} md={12} style={{ marginTop: "90px" }}>
        <Paper
          variant="outlined"
          elevation={0}
          square
          style={{
            paddingTop: "40px",
            paddingBottom: "40px",
            margin: "auto",
          }}
        >
          <Grid container spacing={2}>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              sx={{
                marginBottom: {
                  xs: "50px",
                  md: "30px",
                },
                padding: {
                  xs: "40px",
                  md: "inherit",
                },
                fontSize: {
                  xs: "16px",
                  md: "inherit",
                },
                textAlign: "center",
                fontWeight: "200",
                "&&&&": {
                  padding: {
                    xs: "20px",
                    md: "inherit",
                  },
                },
              }}
            >
              You are not authorised to access this page
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Box>
                <Button
                  variant="outlined"
                  component="a"
                  href="mailto:tech-cp@20minuten.ch?subject=Login%20Request%20(Reporting-Tool)"
                  style={{
                    backgroundColor: "#223647",
                    textTransform: "none",
                    fontSize: "19px",
                    color: "white",
                    "&:hover": {
                      backgroundColor: "#f7f7fa",
                      color: "#223647",
                    },
                  }}
                >
                  Request login
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  </div>
);

export default LoginNope;
