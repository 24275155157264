import ReactJson from "react-json-view";
import { Grid } from "@mui/material";

const DataExplorer = ({ data }) => (
  <Grid container spacing={2}>
    <Grid item xs={12} sm={12} md={12}>
      <ReactJson
        displayDataTypes={false}
        collapsed={2}
        theme="monokai"
        src={data}
      />
    </Grid>
  </Grid>
);

export default DataExplorer;
