import { Skeleton } from "@mui/material";

const ProjectsSkeleton = () => (
  <>
    {[...Array(5)].map((_, i) => (
      <Skeleton
        key={i}
        variant="rectangular"
        height={85}
        style={{ marginBottom: 18 }}
      />
    ))}
  </>
);

export default ProjectsSkeleton;
