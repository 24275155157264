import rootReducer from "./reducers";
import { compose, createStore } from "redux";

import persistState from "redux-localstorage";

// enhancer for persisting store in local storage
const enhancer = compose(persistState());

const store = createStore(rootReducer, enhancer);

export default store;
