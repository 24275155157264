import drillEvents from "./drillEvents";

const calculateCTAClicks = (events) => {
  const ctaClickEventNameCount = drillEvents(events, "cta_click");

  const ctaClickFromOutboundClick = events
    .filter(
      (e) =>
        e.eventName === "outbound_click" && e?.element_type?.includes("CTA"),
    )
    .reduce((p, e) => p + parseInt(e.eventCount), 0);

  return ctaClickEventNameCount + ctaClickFromOutboundClick;
};

export default calculateCTAClicks;
