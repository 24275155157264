import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  components: {
    MuiListItemText: {
      styleOverrides: {
        text: {
          color: "red",
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontWeight: "200",
          "&$focused": {},
        },
      },
    },
    MuiButtonBase: {
      styleOverrides: {
        root: {
          borderRadius: "0px",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: "0px",
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          boxShadow: "0 1px 3px 0 rgba(21, 27, 32, 0.15)",
          borderRadius: "0px",
          borderBottomLeftRadius: "0px",
          borderBottomRightRadius: "0px",
          borderTopLeftRadius: "0px",
          borderTopRightRadius: "0px",
          backgroundColor: "#ffffff",
          fontWeight: "200",
          "&$focused": {},
        },
        formControl: {
          borderBottomLeftRadius: "0px",
          borderBottomRightRadius: "0px",
          borderTopLeftRadius: "0px",
          borderTopRightRadius: "0px",
          height: "53px",
        },
        input: {
          borderBottomLeftRadius: "0px",
          borderBottomRightRadius: "0px",
          borderTopLeftRadius: "0px",
          borderTopRightRadius: "0px",
          fontWeight: "200",
          height: "53px",
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: { borderRadius: "0px" },
        notchedOutline: {
          borderBottomLeftRadius: "0px",
          borderBottomRightRadius: "0px",
          borderTopLeftRadius: "0px",
          borderTopRightRadius: "0px",
          border: "0px",
          height: "53px",
        },
      },
    },
  },
  palette: {
    primary: { main: "#223647" },
    secondary: {
      main: "#223647",
    },
  },
  typography: {
    fontFamily: "BatonTurbo, sans-serif",
    fontWeight: "200",
    body1: {
      fontFamily: "BatonTurbo, sans-serif",
      color: "black",
    },
    h5: {
      fontFamily: "BatonTurbo, sans-serif",
      color: "black",
      fontWeight: "700",
    },
    h6: {
      fontWeight: "700",
    },
  },
  MuiCssBaseline: {
    backgroundColor: "white",
  },
});

export default theme;
