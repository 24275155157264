import {
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from "@mui/material";
import BigNumber from "../BigNumber";

const Longform = ({ longform, index, classes }) => (
  <Accordion
    elevation={0}
    key={index}
    variant="outlined"
    defaultExpanded={index === 0}
    square
    classes={{
      root: classes.storyPanelUnder,
      expanded: classes.storyPanelExpanded,
    }}
  >
    <AccordionSummary
      style={{ margin: 0, width: "100%" }}
      expandIcon={
        <img
          src="/img/icons/chevron-down.svg"
          alt="Expand"
          style={{ width: "28px", height: "28px" }}
        />
      }
      aria-controls="panel1a-content"
    >
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12}>
          <Typography
            variant="h6"
            sx={{
              fontSize: {
                xs: "18px",
                md: "22px",
              },
              color: "#223647",
            }}
          >
            URL: {longform.url}
          </Typography>
        </Grid>
      </Grid>
    </AccordionSummary>

    <AccordionDetails
      style={{
        margin: 0,
        width: "100%",
      }}
    >
      <Typography
        variant="h6"
        className={classes.dfpMetricsKeyBold}
        style={{ marginTop: "1em" }}
      >
        Link:{" "}
      </Typography>
      <Typography
        variant="h6"
        className={classes.dfpMetricsValue}
        style={{ marginTop: "1em", marginBottom: "1.5em" }}
      >
        <a href={longform.url} target="_blank" rel="noreferrer">
          {longform.url}
        </a>
      </Typography>
      <br />
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12}>
          <Grid container spacing={2}>
            <BigNumber
              title="Pageviews"
              value={longform.screenPageViews}
              size="sm"
            />
            <BigNumber
              title="Avg Time on Page"
              value={longform.avgTimeOnPage}
              unit="s"
              size="sm"
            />
            <BigNumber
              title="Inbound Clicks"
              value={longform.inboundClicks}
              size="sm"
            />
            <BigNumber
              title="Outbound Clicks"
              value={longform.outboundClicks}
              size="sm"
            />
            <BigNumber
              title="Slideshow Clicks"
              value={longform.slideshowClicks}
              size="sm"
            />
            <BigNumber
              title="Video Plays"
              value={longform.videoPlays}
              size="sm"
            />
            <BigNumber
              title="Avg Scroll Depth"
              value={longform.scrollDepth}
              unit="%"
              size="sm"
            />
          </Grid>

          {longform.inoundClicks && longform.inoundClicks > 0 && (
            <Accordion
              elevation={0}
              className={classes.storyPanelDFP}
              square
              style={{ marginTop: "1.5em" }}
            >
              <AccordionSummary
                expandIcon={
                  <img
                    src="/img/icons/chevron-down.svg"
                    alt="Expand"
                    style={{ width: "28px", height: "28px" }}
                  />
                }
              >
                <Grid item xs={12} sm={12} md={12}>
                  <Typography
                    variant="h6"
                    className={classes.teaserAccordionSummaryText}
                  >
                    Inound Clicks per URL
                  </Typography>
                </Grid>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container className={classes.gridNoMargin} spacing={2}>
                  <Grid
                    item
                    md={12}
                    sm={12}
                    xs={12}
                    className={classes.clickTableRow}
                  >
                    <Grid
                      container
                      spacing={2}
                      className={classes.clickTableRowInner}
                    >
                      <Grid
                        item
                        xs={9}
                        sm={9}
                        md={9}
                        className={classes.outboundClickUrlContainer}
                      >
                        URL
                      </Grid>
                      <Grid
                        item
                        xs={3}
                        sm={3}
                        md={3}
                        className={classes.outboundClickValueContainer}
                      >
                        <div>Clicks</div>
                      </Grid>
                    </Grid>
                  </Grid>

                  {Object.entries(longform.inoundClicksPerURL)
                    .sort((a, b) => b[1] - a[1])
                    .map(([k, v]) => (
                      <Grid
                        md={12}
                        sm={12}
                        xs={12}
                        className={classes.clickTableRow}
                        key={k}
                      >
                        <Grid
                          container
                          spacing={2}
                          className={classes.clickTableRowInner}
                        >
                          <Grid
                            xs={9}
                            sm={9}
                            md={9}
                            className={classes.outboundClickUrlContainer}
                          >
                            {k}
                          </Grid>
                          <Grid
                            xs={3}
                            sm={3}
                            md={3}
                            className={classes.outboundClickValueContainer}
                          >
                            {v}
                          </Grid>
                        </Grid>
                      </Grid>
                    ))}
                </Grid>
              </AccordionDetails>
            </Accordion>
          )}

          {longform.outboundClicks && longform.outboundClicks > 0 && (
            <Accordion
              elevation={0}
              className={classes.storyPanelDFP}
              square
              style={{ marginTop: "1.5em" }}
            >
              <AccordionSummary
                expandIcon={
                  <img
                    src="/img/icons/chevron-down.svg"
                    alt="Expand"
                    style={{ width: "28px", height: "28px" }}
                  />
                }
              >
                <Grid item xs={12} sm={12} md={12}>
                  <Typography
                    variant="h6"
                    className={classes.teaserAccordionSummaryText}
                  >
                    Outbound Clicks per URL
                  </Typography>
                </Grid>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container className={classes.gridNoMargin} spacing={2}>
                  <Grid
                    item
                    md={12}
                    sm={12}
                    xs={12}
                    className={classes.clickTableRow}
                  >
                    <Grid
                      container
                      spacing={2}
                      className={classes.clickTableRowInner}
                    >
                      <Grid
                        item
                        xs={9}
                        sm={9}
                        md={9}
                        className={classes.outboundClickUrlContainer}
                      >
                        URL
                      </Grid>
                      <Grid
                        item
                        xs={3}
                        sm={3}
                        md={3}
                        className={classes.outboundClickValueContainer}
                      >
                        <div>Clicks</div>
                      </Grid>
                    </Grid>
                  </Grid>

                  {Object.entries(longform.outboundClicksPerURL)
                    .sort((a, b) => b[1] - a[1])
                    .map(([k, v]) => (
                      <Grid
                        md={12}
                        sm={12}
                        xs={12}
                        className={classes.clickTableRow}
                        key={k}
                      >
                        <Grid
                          container
                          spacing={2}
                          className={classes.clickTableRowInner}
                        >
                          <Grid
                            xs={9}
                            sm={9}
                            md={9}
                            className={classes.outboundClickUrlContainer}
                          >
                            {k}
                          </Grid>
                          <Grid
                            xs={3}
                            sm={3}
                            md={3}
                            className={classes.outboundClickValueContainer}
                          >
                            {v}
                          </Grid>
                        </Grid>
                      </Grid>
                    ))}
                </Grid>
              </AccordionDetails>
            </Accordion>
          )}
        </Grid>
      </Grid>
    </AccordionDetails>
  </Accordion>
);

export default Longform;
