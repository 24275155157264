import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  Grid,
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Link as MuiLink,
} from "@mui/material";
import moment from "moment";
import numeral from "numeral";
import drillEvents from "../utils/drillEvents";
import BigNumber from "../BigNumber";
import ABTest from "./ABTest";
import kpiVocabulary from "../../../vocabulary/kpis";
import calculateCTAClicks from "../utils/ctaClick";

const mediaTitleLabels = {
  "20min-de": "20 Minuten",
  "20min-fr": "20 minutes",
  "24heures-fr": "24 heures",
  "annabelle-de": "Annabelle",
  "baz-de": "Basler Zeitung",
  "bilan-fr": "Bilan",
  "bund-de": "Der Bund",
  "bz-de": "Berner Zeitung",
  "cp-de": "Commercial Publishing",
  "cp-fr": "Commercial Publishing",
  "cp-it": "Commercial Publishing",
  "-de": "Content Hub",
  "encore-fr": "Encore",
  "femina-fr": "Femina",
  "-fr": "Content Hub",
  "fuw-de": "Finanz und Wirtschaft",
  "-it": "Content Hub",
  "lb-de": "Der Landbote",
  "le-matin-fr": "Le Matin",
  "lifestyle-de": "20 Minuten Lifestyle",
  "lifestyle-fr": "20 minutes Lifestyle",
  "tagi-de": "Tages Anzeiger",
  "tdg-fr": "Tribune de Genève",
  "tio-it": "Tio",
  "zsz-de": "Zürichsee Zeitung",
  "zul-de": "Zürcher Unterländer",
};

const mediaTitleNoLangLabels = {
  "20min": "20 Minuten",
  "24heures": "24 heures",
  annabelle: "Annabelle",
  bazonline: "Basler Zeitung",
  bernerzeitung: "Berner Zeitung",
  bilan: "Bilan",
  contentHub: "Content Hub",
  cp: "Commercial Publishing",
  derbund: "Der Bund",
  encore: "Encore",
  femina: "Femina",
  fuw: "Finanz und Wirtschaft",
  lb: "Der Landbote",
  lematin: "Le Matin",
  lifestyle: "20 Minuten Lifestyle",
  tagesanzeiger: "Tages Anzeiger",
  tagi: "Tages Anzeiger",
  tdg: "Tribune de Genève",
  tio: "Tio",
  zsz: "Zürichsee Zeitung",
  zul: "Zürcher Unterländer",
};

const dfpMediaTitleLabels = {
  "20minuten-front": "20 Minuten Front",
  "20minuten-wettbewerb": "20 Minuten Wettbewerb",
  "20minuten-DE": "20 Minuten",
  "20minutes-FR": "20 minutes",
  "20minuti-Tio-IT": "tio",
  Annabelle: "Annabelle",
  Bilan: "Bilan",
  "encore-DE": "Encore",
  "encore-FR": "Encore",
  Femina: "Femina",
  FUW: "Finanz und Wirtschaft",
  "NN-Baslerzeitung": "Basler Zeitung",
  "NN-Berneroberlaender": "Berner Oberländer",
  "NN-Bernerzeitung": "Berner Zeitung",
  "NN-Derbund": "Der Bund",
  "NNf-24heures": "24 heures",
  "NNf-Lematin": "Le Matin",
  "NNf-Tdg": "Tribune de Genève",
  "NN-Langenthalertagblatt": "Langenthaler Tagblatt",
  "NN-Tagesanzeiger": "Tages Anzeiger",
  "NN-Thunertagblatt": "Thuner Tagblatt",
  NNz_Landbote: "Der Landbote",
  NNz_ZuercherUnterlaender: "Zürcher Unterländer",
  NNz_ZuerichseeZeitung: "Zürichsee Zeitung",
};

const ctaCategoryLabels = {
  videoPlay: "Video Plays",
  contestParticipation: "Participation in the Contest",
  redirectToProduct: "Redirect to Product",
  redirectToPage: "Redirect to Page",
};

// strip language infos from story-ID
const trimStoryID = (storyID) => storyID.replace(/-\w{2}/, "");

const Story = ({ story, classes, index, kpis }) => {
  const { metadata, isDailyDeal } = story;

  const isLoggedIn = useSelector((state) => state.isLoggedIn);
  const [storyKPIs, setStoryKPIs] = useState({});
  const [mediaTitleChoices, setMediaTitleChoices] = useState([]);
  const [teaserMediaTitleFilter, setTeaserMediaTitleFilter] = useState([]);

  story.creativesWithDFP = story.creativesWithDFP.map((c) => ({
    ...c,
    ctr: c.impressions ? (c.clicks / c.impressions) * 100 : 0,
  }));

  useEffect(() => {
    if (Array.isArray(kpis) && kpis.length > 0) {
      const kpiFound = kpis.find((e) => String(e.key) === String(story.id));
      if (kpiFound) {
        const generateOutboundClicksPerURL = (events) => {
          const outboundClicksStartWithOutboundLink = events.filter(
            (e) =>
              (e.eventName === "click" && Boolean(e.outbound)) ||
              e.eventName.startsWith("outboundlink_"),
          );
          const outboundClicksWithoutCTA = events.filter(
            (e) =>
              e.eventName === "outbound_click" &&
              !e?.element_type?.includes("CTA"),
          );
          const outboundClicks = outboundClicksStartWithOutboundLink
            .concat(outboundClicksWithoutCTA)
            .reduce((p, e) => {
              let linkUrl =
                e.outbound_url === "(not set)" ? "" : e.outbound_url;

              // fallback to e.linkUrl
              if (!linkUrl && e.linkUrl) {
                linkUrl = e.linkUrl;
              }

              if (!linkUrl) {
                return p;
              }

              if (!p[linkUrl]) {
                p[linkUrl] = 0;
              }

              p[linkUrl] += parseInt(e.eventCount);
              return p;
            }, {});
          return Object.entries(outboundClicks)
            .map(([url, clickCount]) => ({
              url,
              clickCount,
            }))
            .sort((a, b) => b.clickCount - a.clickCount);
        };

        const generateCtaClickList = (events) => {
          const eventsToFilter = Array.isArray(events) ? events : [];

          const ctaCollection = [];
          const ctaUnique = new Set();
          const ctaSummedUp = [];

          const filtered = eventsToFilter.filter((e) => e.key === "ctaClick");

          if (Array.isArray(filtered)) {
            filtered.forEach((e) => {
              const raw = e.value && e.value.raw ? e.value.raw : [];

              if (Array.isArray(raw)) {
                raw.forEach((r) => {
                  ctaCollection.push(r);
                  ctaUnique.add(r.eventName);
                });
              }
            });
          }

          const ctaUniqueArray = Array.from(ctaUnique);

          ctaUniqueArray.forEach((l) => {
            const foundCTAs = ctaCollection.filter((f) => l === f.eventName);

            let eventCount = 0;

            foundCTAs.forEach((f) => {
              eventCount += Number(f.eventCount);
            });

            const obj = {
              ctaCategory: l,
              eventCount,
            };

            ctaSummedUp.push(obj);
          });

          return ctaSummedUp;
        };

        const filterCtaClickListGroupByElementName = (events) => {
          const groupedCTA = events
            .filter(
              (e) =>
                e.eventName === "outbound_click" &&
                e?.element_type?.includes("CTA"),
            )
            .reduce((result, item) => {
              const { element_name } = item;
              let eventCount = parseInt(item.eventCount, 10);

              if (isNaN(eventCount)) {
                eventCount = 0;
              }

              if (!result[element_name]) {
                result[element_name] = { element_name, eventCount: 0 };
              }

              result[element_name].eventCount += eventCount;

              return result;
            }, {});

          return Object.values(groupedCTA);
        };

        const sumDFPMetrics = (creativesWithDFP, metricName) => {
          let total = 0;

          creativesWithDFP.forEach((c) => {
            if (c[metricName]) {
              total += c[metricName];
            }
          });

          return total;
        };

        const avgScrollDepth = (events) => {
          if (!Array.isArray(events)) {
            return 0;
          }

          const pageViews =
            parseInt(
              events.find((e) => e.eventName === "page_view")?.eventCount,
              10,
            ) || 0;

          if (pageViews === 0) {
            return 0;
          }

          const eventCount_scroll_100 =
            parseInt(
              events.find(
                (e) => e.eventName === "scroll" && e.percentScrolled === "100",
              )?.eventCount,
              10,
            ) || 0;
          const eventCount_scroll_end =
            parseInt(
              events.find((e) => e.eventName === "scroll_end")?.eventCount,
              10,
            ) || 0;
          const eventCount_scroll_75 =
            parseInt(
              events.find(
                (e) => e.eventName === "scroll" && e.percentScrolled === "75",
              )?.eventCount,
              10,
            ) || 0;
          const eventCount_scroll_50 =
            parseInt(
              events.find(
                (e) => e.eventName === "scroll" && e.percentScrolled === "50",
              )?.eventCount,
              10,
            ) || 0;
          const eventCount_scroll_25 =
            parseInt(
              events.find(
                (e) => e.eventName === "scroll" && e.percentScrolled === "25",
              )?.eventCount,
              10,
            ) || 0;

          const scroll_100 = 100 * eventCount_scroll_100;
          const scroll_90 =
            90 * Math.abs(eventCount_scroll_end - eventCount_scroll_100);
          const scroll_75 =
            75 * Math.abs(eventCount_scroll_75 - eventCount_scroll_end);
          const scroll_50 =
            50 * Math.abs(eventCount_scroll_50 - eventCount_scroll_75);
          const scroll_25 =
            25 * Math.abs(eventCount_scroll_25 - eventCount_scroll_50);

          return (
            (scroll_100 + scroll_90 + scroll_75 + scroll_50 + scroll_25) /
            pageViews
          );
        };

        const outboundClicksWithOutboundLink = kpiFound.events
          .filter(
            (e) =>
              (e.eventName === "click" && Boolean(e.outbound)) ||
              e.eventName.startsWith("outboundlink_"),
          )
          .reduce((p, e) => p + parseInt(e.eventCount), 0);
        const outboundClicksWithoutCTA = kpiFound.events
          .filter(
            (e) =>
              e.eventName === "outbound_click" &&
              !e?.element_type?.includes("CTA"),
          )
          .reduce((p, e) => p + parseInt(e.eventCount), 0);

        const data = {
          ...kpiFound.value,
          impressions: Array.isArray(kpiFound.creativesFiltered)
            ? sumDFPMetrics(kpiFound.creativesFiltered, "impressions")
            : undefined,
          clicks: Array.isArray(kpiFound.creativesFiltered)
            ? sumDFPMetrics(kpiFound.creativesFiltered, "clicks")
            : undefined,
          ctr: Array.isArray(kpiFound.creativesFiltered)
            ? (sumDFPMetrics(kpiFound.creativesFiltered, "clicks") /
                sumDFPMetrics(kpiFound.creativesFiltered, "impressions")) *
              100
            : undefined,
          videoPlays:
            drillEvents(kpiFound.events, "video_play") +
            drillEvents(kpiFound.events, "video_start"),
          outboundClicks:
            outboundClicksWithoutCTA + outboundClicksWithOutboundLink,
          ctaClicks: calculateCTAClicks(kpiFound.events),
          shares:
            drillEvents(kpiFound.events, "share_start") +
            drillEvents(kpiFound.events, "open_share_options") +
            drillEvents(kpiFound.events, "begin_to_share") +
            drillEvents(kpiFound.events, "share"),
          formSubmissions: drillEvents(kpiFound.events, "form_submit"),
          scrollDepth: avgScrollDepth(kpiFound.events),
          outboundClicksPerURL: generateOutboundClicksPerURL(kpiFound.events),
          ctaClicksPerElementName: filterCtaClickListGroupByElementName(
            kpiFound.events,
          ),
          ctaClickList: generateCtaClickList(kpiFound.events),
        };

        setStoryKPIs(data);
      }
    }
  }, [kpis, story, story.id, isDailyDeal]);

  useEffect(() => {
    if (story && Array.isArray(story.creativesWithDFP)) {
      const teaserMediaTitles = new Set();
      story.creativesWithDFP.forEach((c) => {
        if (Array.isArray(c.media_title_codes)) {
          c.media_title_codes.forEach((mt) => {
            teaserMediaTitles.add(mt);
          });
        }
      });

      setMediaTitleChoices(Array.from(teaserMediaTitles));
      setTeaserMediaTitleFilter(Array.from(teaserMediaTitles));
    }
  }, [story]);

  const toggleMediaTitle = (name) => () => {
    const index = teaserMediaTitleFilter.indexOf(name);

    if (index === -1) {
      const newFilter = [...teaserMediaTitleFilter, name];

      setTeaserMediaTitleFilter(newFilter);
    } else if (index >= 0) {
      const newFilter = teaserMediaTitleFilter.filter((t) => t !== name);
      setTeaserMediaTitleFilter(newFilter);
    }
  };

  return (
    <Accordion
      elevation={0}
      key={index}
      variant="outlined"
      defaultExpanded={index === 0}
      square
      classes={{
        root: classes.storyPanelUnder,
        expanded: classes.storyPanelExpanded,
      }}
    >
      <AccordionSummary
        style={{ margin: 0, width: "100%" }}
        expandIcon={
          <img
            src="/img/icons/chevron-down.svg"
            alt="Expand"
            style={{ width: "28px", height: "28px" }}
          />
        }
        aria-controls="panel1a-content"
      >
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={12}>
            <Typography
              variant="h6"
              sx={{
                fontSize: {
                  xs: "18px",
                  md: "22px",
                },
                color: "#223647",
              }}
            >
              ID {trimStoryID(story.id)}: {story.name}
            </Typography>
          </Grid>
        </Grid>
      </AccordionSummary>

      <AccordionDetails
        style={{
          margin: 0,
          width: "100%",
        }}
      >
        <Grid container spacing={2}>
          {metadata && !Array.isArray(metadata)
            ? story.languages.map((l) => (
                <Grid key={l.locale} item xs={12} sm={12} md={12}>
                  <Grid container spacing={2}>
                    <Grid item xs={3} sm={3} md={3}>
                      {metadata && metadata.og_image ? (
                        <Box
                          className={classes.imgBoxSquare}
                          style={{
                            backgroundImage: `url(${
                              metadata.og_image[l.locale].src
                            })`,
                          }}
                        />
                      ) : null}
                    </Grid>
                    <Grid item xs={9} sm={9} md={9}>
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} md={12}>
                          <Typography variant="h6" className={classes.ogTitle}>
                            {metadata.og_title[l.locale]}
                          </Typography>
                          <Typography
                            variant="body1"
                            className={classes.ogDescription}
                          >
                            {metadata.og_description[l.locale]}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              ))
            : null}
          {story ? (
            <Grid item xs={12} sm={12} md={12}>
              <Grid container spacing={2}>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  className={classes.storyInfosWrapper}
                >
                  {story && story.publisher && isLoggedIn ? (
                    <div className={classes.storyInfos}>
                      <span className={classes.storyInfosKey}>Created by:</span>
                      <span className={classes.storyInfosValue}>
                        {story.creator.name}
                      </span>
                    </div>
                  ) : null}
                  {story && story.format ? (
                    <div className={classes.storyInfos}>
                      <span className={classes.storyInfosKey}>Format:</span>
                      <span className={classes.storyInfosValue}>
                        {story.format.name}
                      </span>
                    </div>
                  ) : null}
                  {story && story.optimization_target ? (
                    <div className={classes.storyInfos}>
                      <span className={classes.storyInfosKey}>Goal:</span>
                      <span className={classes.storyInfosValue}>
                        {story.optimization_target.name}
                      </span>
                    </div>
                  ) : null}
                </Grid>
              </Grid>
            </Grid>
          ) : null}
          <Grid item xs={12} sm={12} md={12}>
            <Grid container spacing={2}>
              <BigNumber
                title="Pageviews"
                value={storyKPIs.screenPageViews}
                size="sm"
              />
              <BigNumber
                title="Avg Time on Page"
                value={storyKPIs.averageSessionDuration}
                unit="s"
                size="sm"
              />
              <BigNumber
                title="CTA Clicks"
                value={storyKPIs.ctaClicks}
                size="sm"
              />
              <BigNumber
                title="Outbound Clicks"
                value={storyKPIs.outboundClicks}
                size="sm"
              />
              <BigNumber
                title="Video Plays"
                value={
                  story.gaSummarized && story.gaSummarized.videoPlays
                    ? story.gaSummarized.videoPlays
                    : storyKPIs.videoPlays
                }
                size="sm"
              />

              <BigNumber
                title="Avg Scroll Depth"
                value={storyKPIs.scrollDepth}
                unit="%"
                size="sm"
              />

              <BigNumber value={storyKPIs.shares} title="Shares" size="sm" />

              <BigNumber
                value={storyKPIs.formSubmissions}
                title={kpiVocabulary.formSubmissions.description}
                size="sm"
              />
              <BigNumber
                value={storyKPIs.impressions}
                title="Impressions"
                size="sm"
              />

              <BigNumber value={storyKPIs.clicks} title="Clicks" size="sm" />

              <BigNumber
                value={storyKPIs.ctr}
                title="CTR"
                unit="%"
                decimals={3}
                size="sm"
              />
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            {(Array.isArray(story.media_titles) ||
              story.reportType === "URL") &&
            !story.isLinkoutCampaign &&
            !isDailyDeal ? (
              <Grid container className={classes.gridNoMargin} spacing={2}>
                <Typography
                  style={{
                    fontSize: "16px",
                    textDecoration: "none",
                    fontWeight: "bold",
                  }}
                >
                  Story-Links:
                </Typography>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    flexWrap: "wrap",
                    "&>:lastChild:after": {
                      content: '""',
                    },
                  }}
                >
                  {Object.keys(story.links).map((mediaTitle) =>
                    Object.keys(story.links[mediaTitle]).map((lang) => (
                      <div
                        key={`${mediaTitle - lang}`}
                        style={{
                          display: "flex",
                          flexDirection: "flex-row",
                          "&:after": {
                            content: '" /"',
                          },
                        }}
                      >
                        <Typography
                          style={{
                            textDecoration: "underline",
                            fontFamily: "BatonTurbo-Book",
                            color: "#262627",
                            marginLeft: "15px",
                            marginRight: "15px",
                          }}
                        >
                          <MuiLink
                            href={story.links[mediaTitle][lang]}
                            target="_blank"
                            rel="noopener"
                          >
                            {mediaTitleLabels[`${mediaTitle}-${lang}`]
                              ? mediaTitleLabels[`${mediaTitle}-${lang}`]
                              : `${mediaTitle} - ${lang}`}
                          </MuiLink>
                        </Typography>
                      </div>
                    )),
                  )}
                </div>
              </Grid>
            ) : null}
          </Grid>
          {storyKPIs && Array.isArray(storyKPIs.byMediaTitle) && (
            <Accordion elevation={0} className={classes.storyPanelDFP} square>
              <AccordionSummary
                expandIcon={
                  <img
                    src="/img/icons/chevron-down.svg"
                    alt="Expand"
                    style={{ width: "28px", height: "28px" }}
                  />
                }
              >
                <Grid item xs={12} sm={12} md={12}>
                  <Typography
                    variant="h6"
                    className={classes.teaserAccordionSummaryText}
                  >
                    Pageviews per Media Title
                  </Typography>
                </Grid>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container className={classes.gridNoMargin} spacing={2}>
                  <Grid
                    item
                    md={12}
                    sm={12}
                    xs={12}
                    className={classes.clickTableRow}
                  >
                    <Grid
                      container
                      spacing={2}
                      className={classes.clickTableRowInner}
                    >
                      <Grid
                        item
                        xs={6}
                        sm={6}
                        md={6}
                        className={classes.outboundClickUrlContainer}
                      >
                        Media Title
                      </Grid>
                      <Grid
                        item
                        xs={3}
                        sm={3}
                        md={3}
                        className={classes.outboundClickValueContainer}
                      >
                        Pageviews
                      </Grid>
                      <Grid
                        item
                        xs={3}
                        sm={3}
                        md={3}
                        className={classes.outboundClickValueContainer}
                      >
                        Avg Time on Page
                      </Grid>
                    </Grid>
                  </Grid>

                  {storyKPIs && Array.isArray(storyKPIs.byMediaTitle)
                    ? storyKPIs.byMediaTitle
                        .sort(
                          (a, b) =>
                            b.value.screenPageViews - a.value.screenPageViews,
                        )
                        .map((lc) => (
                          <Grid
                            md={12}
                            sm={12}
                            xs={12}
                            className={classes.clickTableRow}
                            key={lc.key}
                          >
                            <Grid
                              container
                              spacing={2}
                              className={classes.clickTableRowInner}
                            >
                              <Grid
                                xs={6}
                                sm={6}
                                md={6}
                                className={classes.outboundClickUrlContainer}
                              >
                                {mediaTitleNoLangLabels[lc.key]
                                  ? mediaTitleNoLangLabels[lc.key]
                                  : lc.key}
                              </Grid>
                              <Grid
                                xs={3}
                                sm={3}
                                md={3}
                                className={classes.outboundClickValueContainer}
                              >
                                <div>
                                  {lc.value && lc.value.screenPageViews
                                    ? lc.value.screenPageViews
                                    : 0}
                                </div>
                              </Grid>
                              <Grid
                                xs={3}
                                sm={3}
                                md={3}
                                className={classes.outboundClickValueContainer}
                              >
                                {" "}
                                <div>
                                  {lc.value && lc.value.averageSessionDuration
                                    ? numeral(
                                        lc.value.averageSessionDuration,
                                      ).format("0.0")
                                    : 0}
                                  s
                                </div>
                              </Grid>
                            </Grid>
                          </Grid>
                        ))
                    : null}
                </Grid>
              </AccordionDetails>
            </Accordion>
          )}
          {storyKPIs &&
            Array.isArray(storyKPIs.outboundClicksPerURL) &&
            storyKPIs.outboundClicksPerURL.length > 0 && (
              <Accordion elevation={0} className={classes.storyPanelDFP} square>
                <AccordionSummary
                  expandIcon={
                    <img
                      src="/img/icons/chevron-down.svg"
                      alt="Expand"
                      style={{ width: "28px", height: "28px" }}
                    />
                  }
                >
                  <Grid item xs={12} sm={12} md={12}>
                    <Typography
                      variant="h6"
                      className={classes.teaserAccordionSummaryText}
                    >
                      Outbound Clicks per URL
                    </Typography>
                  </Grid>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container className={classes.gridNoMargin} spacing={2}>
                    <Grid
                      item
                      md={12}
                      sm={12}
                      xs={12}
                      className={classes.clickTableRow}
                    >
                      <Grid
                        container
                        spacing={2}
                        className={classes.clickTableRowInner}
                      >
                        <Grid
                          item
                          xs={9}
                          sm={9}
                          md={9}
                          className={classes.outboundClickUrlContainer}
                        >
                          URL
                        </Grid>
                        <Grid
                          item
                          xs={3}
                          sm={3}
                          md={3}
                          className={classes.outboundClickValueContainer}
                        >
                          <div>Clicks</div>
                        </Grid>
                      </Grid>
                    </Grid>

                    {storyKPIs &&
                      Array.isArray(storyKPIs.outboundClicksPerURL) &&
                      storyKPIs.outboundClicksPerURL.map((lc) => (
                        <Grid
                          md={12}
                          sm={12}
                          xs={12}
                          className={classes.clickTableRow}
                          key={lc.url}
                        >
                          <Grid
                            container
                            spacing={2}
                            className={classes.clickTableRowInner}
                          >
                            <Grid
                              xs={9}
                              sm={9}
                              md={9}
                              className={classes.outboundClickUrlContainer}
                            >
                              {lc.url}
                            </Grid>
                            <Grid
                              xs={3}
                              sm={3}
                              md={3}
                              className={classes.outboundClickValueContainer}
                            >
                              {lc.clickCount}
                            </Grid>
                          </Grid>
                        </Grid>
                      ))}
                  </Grid>
                </AccordionDetails>
              </Accordion>
            )}

          {storyKPIs &&
            Array.isArray(storyKPIs.ctaClicksPerElementName) &&
            storyKPIs.ctaClicksPerElementName.length > 0 && (
              <Accordion elevation={0} className={classes.storyPanelDFP} square>
                <AccordionSummary
                  expandIcon={
                    <img
                      src="/img/icons/chevron-down.svg"
                      alt="Expand"
                      style={{ width: "28px", height: "28px" }}
                    />
                  }
                >
                  <Grid item xs={12} sm={12} md={12}>
                    <Typography
                      variant="h6"
                      className={classes.teaserAccordionSummaryText}
                    >
                      Clicks on Call to Action (CTA)
                    </Typography>
                  </Grid>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container className={classes.gridNoMargin} spacing={2}>
                    <Grid
                      item
                      md={12}
                      sm={12}
                      xs={12}
                      className={classes.clickTableRow}
                    >
                      <Grid
                        container
                        spacing={2}
                        className={classes.clickTableRowInner}
                      >
                        <Grid
                          item
                          xs={9}
                          sm={9}
                          md={9}
                          className={classes.outboundClickUrlContainer}
                        >
                          CTA category
                        </Grid>
                        <Grid
                          item
                          xs={3}
                          sm={3}
                          md={3}
                          className={classes.outboundClickValueContainer}
                        >
                          {" "}
                          <div>Clicks</div>
                        </Grid>
                      </Grid>
                    </Grid>

                    {storyKPIs &&
                      Array.isArray(storyKPIs.ctaClicksPerElementName) &&
                      storyKPIs.ctaClicksPerElementName
                        .sort((a, b) => b.eventCount - a.eventCount)
                        .map((lc) => (
                          <Grid
                            md={12}
                            sm={12}
                            xs={12}
                            className={classes.clickTableRow}
                            key={lc.element_name}
                          >
                            <Grid
                              container
                              spacing={2}
                              className={classes.clickTableRowInner}
                            >
                              <Grid
                                xs={9}
                                sm={9}
                                md={9}
                                className={classes.outboundClickUrlContainer}
                              >
                                {lc.element_name}
                              </Grid>
                              <Grid
                                xs={3}
                                sm={3}
                                md={3}
                                className={classes.outboundClickValueContainer}
                              >
                                {lc.eventCount}
                              </Grid>
                            </Grid>
                          </Grid>
                        ))}
                  </Grid>
                </AccordionDetails>
              </Accordion>
            )}

          {story.gaSummarized && story.gaSummarized.videoMetricsByTitle ? (
            <Accordion elevation={0} className={classes.storyPanelDFP} square>
              <AccordionSummary
                expandIcon={
                  <img
                    src="/img/icons/chevron-down.svg"
                    alt="Expand"
                    style={{ width: "28px", height: "28px" }}
                  />
                }
              >
                <Grid item xs={12} sm={12} md={12}>
                  <Typography
                    variant="h6"
                    className={classes.teaserAccordionSummaryText}
                  >
                    Video Metrics
                  </Typography>
                </Grid>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container className={classes.gridNoMargin} spacing={2}>
                  <Grid
                    item
                    md={12}
                    sm={12}
                    xs={12}
                    className={classes.clickTableRow}
                  >
                    <Grid
                      container
                      spacing={2}
                      className={classes.clickTableRowInner}
                    >
                      <Grid
                        item
                        xs={6}
                        sm={6}
                        md={6}
                        className={classes.outboundClickUrlContainer}
                      >
                        Video Title
                      </Grid>
                      <Grid
                        item
                        xs={3}
                        sm={3}
                        md={3}
                        className={classes.outboundClickValueContainer}
                      >
                        Video Progress 25%
                      </Grid>
                      <Grid
                        item
                        xs={1}
                        sm={1}
                        md={1}
                        className={classes.outboundClickValueContainer}
                      >
                        50%
                      </Grid>
                      <Grid
                        item
                        xs={1}
                        sm={1}
                        md={1}
                        className={classes.outboundClickValueContainer}
                      >
                        75%
                      </Grid>
                      <Grid
                        item
                        xs={1}
                        sm={1}
                        md={1}
                        className={classes.outboundClickValueContainer}
                      >
                        100%
                      </Grid>
                    </Grid>
                  </Grid>

                  {Object.entries(story.gaSummarized.videoMetricsByTitle).map(
                    ([key, videoMetricByTitle]) => (
                      <Grid
                        md={12}
                        sm={12}
                        xs={12}
                        className={classes.clickTableRow}
                        key={key}
                      >
                        <Grid
                          container
                          spacing={2}
                          className={classes.clickTableRowInner}
                        >
                          <Grid
                            xs={6}
                            sm={6}
                            md={6}
                            className={classes.outboundClickUrlContainer}
                          >
                            {videoMetricByTitle.title}
                          </Grid>
                          <Grid
                            xs={3}
                            sm={3}
                            md={3}
                            className={classes.outboundClickValueContainer}
                          >
                            <div>
                              {Math.max(
                                videoMetricByTitle.videoProgress.progress25 -
                                  videoMetricByTitle.videoProgress.progress50,
                                0,
                              )}
                            </div>
                          </Grid>
                          <Grid
                            xs={1}
                            sm={1}
                            md={1}
                            className={classes.outboundClickValueContainer}
                          >
                            <div>
                              {Math.max(
                                videoMetricByTitle.videoProgress.progress50 -
                                  videoMetricByTitle.videoProgress.progress75,
                                0,
                              )}
                            </div>
                          </Grid>
                          <Grid
                            xs={1}
                            sm={1}
                            md={1}
                            className={classes.outboundClickValueContainer}
                          >
                            <div>
                              {Math.max(
                                videoMetricByTitle.videoProgress.progress75 -
                                  videoMetricByTitle.videoProgress.progress100,
                                0,
                              )}
                            </div>
                          </Grid>
                          <Grid
                            xs={1}
                            sm={1}
                            md={1}
                            className={classes.outboundClickValueContainer}
                          >
                            <div>
                              {videoMetricByTitle.videoProgress.progress100}
                            </div>
                          </Grid>
                        </Grid>
                      </Grid>
                    ),
                  )}
                </Grid>
              </AccordionDetails>
            </Accordion>
          ) : null}

          {storyKPIs &&
            Array.isArray(storyKPIs.ctaClickList) &&
            storyKPIs.ctaClickList.length > 0 && (
              <Accordion elevation={0} className={classes.storyPanelDFP} square>
                <AccordionSummary
                  expandIcon={
                    <img
                      src="/img/icons/chevron-down.svg"
                      alt="Expand"
                      style={{ width: "28px", height: "28px" }}
                    />
                  }
                >
                  <Grid item xs={12} sm={12} md={12}>
                    <Typography
                      variant="h6"
                      className={classes.teaserAccordionSummaryText}
                    >
                      Clicks on Call to Action (CTA)
                    </Typography>
                  </Grid>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container className={classes.gridNoMargin} spacing={2}>
                    <Grid
                      item
                      md={12}
                      sm={12}
                      xs={12}
                      className={classes.clickTableRow}
                    >
                      <Grid
                        container
                        spacing={2}
                        className={classes.clickTableRowInner}
                      >
                        <Grid
                          item
                          xs={9}
                          sm={9}
                          md={9}
                          className={classes.outboundClickUrlContainer}
                        >
                          CTA category
                        </Grid>
                        <Grid
                          item
                          xs={3}
                          sm={3}
                          md={3}
                          className={classes.outboundClickValueContainer}
                        >
                          {" "}
                          <div>Clicks</div>
                        </Grid>
                      </Grid>
                    </Grid>

                    {storyKPIs &&
                      Array.isArray(storyKPIs.ctaClickList) &&
                      storyKPIs.ctaClickList
                        .sort((a, b) => b.eventCount - a.eventCount)
                        .map((lc) => (
                          <Grid
                            md={12}
                            sm={12}
                            xs={12}
                            className={classes.clickTableRow}
                            key={lc.ctaCategory}
                          >
                            <Grid
                              container
                              spacing={2}
                              className={classes.clickTableRowInner}
                            >
                              <Grid
                                xs={9}
                                sm={9}
                                md={9}
                                className={classes.outboundClickUrlContainer}
                              >
                                {lc.ctaCategory &&
                                ctaCategoryLabels[lc.ctaCategory]
                                  ? ctaCategoryLabels[lc.ctaCategory]
                                  : lc.ctaCategory}
                              </Grid>
                              <Grid
                                xs={3}
                                sm={3}
                                md={3}
                                className={classes.outboundClickValueContainer}
                              >
                                {lc.eventCount}
                              </Grid>
                            </Grid>
                          </Grid>
                        ))}
                  </Grid>
                </AccordionDetails>
              </Accordion>
            )}

          {story.reportType !== "URL" ? (
            <Accordion elevation={0} className={classes.storyPanelDFP} square>
              <AccordionSummary
                expandIcon={
                  <img
                    src="/img/icons/chevron-down.svg"
                    alt="Expand"
                    style={{ width: "28px", height: "28px" }}
                  />
                }
              >
                <Grid item xs={12} sm={12} md={12}>
                  <Typography
                    variant="h6"
                    className={classes.teaserAccordionSummaryText}
                  >
                    Teaser A/B Tests
                  </Typography>
                </Grid>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container spacing={2}>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      flexWrap: "wrap",
                    }}
                  >
                    {mediaTitleChoices.map((mt) => (
                      <span
                        key={mt}
                        onClick={toggleMediaTitle(mt)}
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          cursor: "pointer",
                        }}
                        className={
                          teaserMediaTitleFilter.includes(mt)
                            ? classes.mediaTitlesSelected
                            : classes.mediaTitlesNotSelected
                        }
                      >
                        <Box style={{ display: "flex" }}>
                          {teaserMediaTitleFilter.includes(mt) ? (
                            <img
                              src="/img/icons/check-circle-selected.svg"
                              alt="Selected"
                              style={{
                                width: "14px",
                                height: "14px",
                                alignSelf: "center",
                                marginRight: "5px",
                              }}
                            />
                          ) : (
                            <img
                              src="/img/icons/check-circle-not-selected.svg"
                              alt="Not selected"
                              style={{
                                width: "14px",
                                height: "14px",
                                alignSelf: "center",
                                marginRight: "5px",
                              }}
                            />
                          )}
                        </Box>
                        <span>
                          {dfpMediaTitleLabels[mt]
                            ? dfpMediaTitleLabels[mt]
                            : mt}
                        </span>
                      </span>
                    ))}
                  </Grid>
                  <Grid item xs={12} sm={12} md={12}>
                    {story &&
                      Array.isArray(story.creativesWithDFP) &&
                      story.creativesWithDFP
                        .sort((a, b) => b.ctr - a.ctr)
                        .map((creative) => {
                          let isMatch = false;
                          const mediaTitleCodes = creative.media_title_codes;

                          mediaTitleCodes.forEach((c) => {
                            if (teaserMediaTitleFilter.includes(c)) {
                              isMatch = true;
                            }
                          });

                          if (isMatch) {
                            return (
                              <Grid
                                key={creative.id}
                                item
                                xs={12}
                                sm={12}
                                md={12}
                                className={classes.dfpCreativeWrapper}
                                style={{
                                  opacity:
                                    creative.is_active === false ? 0.3 : 1,
                                }}
                              >
                                <Grid container spacing={2}>
                                  <Grid item xs={3} sm={3} md={3}>
                                    <Box
                                      className={classes.imgBoxSquare}
                                      style={{
                                        backgroundImage: `url(${creative.image})`,
                                      }}
                                    ></Box>
                                  </Grid>
                                  <Grid item xs={9} sm={9} md={9}>
                                    <Grid container spacing={2}>
                                      <Grid item xs={12} sm={12} md={12}>
                                        <Typography
                                          variant="h6"
                                          className={classes.dfpCreativeTitle}
                                        >
                                          {Array.isArray(
                                            creative.media_title_codes,
                                          )
                                            ? creative.media_title_codes.map(
                                                (mt) => (
                                                  <span
                                                    key={mt}
                                                    className={
                                                      classes.mediaTitleHeader
                                                    }
                                                  >
                                                    {dfpMediaTitleLabels[mt]
                                                      ? dfpMediaTitleLabels[mt]
                                                      : mt}
                                                  </span>
                                                ),
                                              )
                                            : null}
                                          {creative.is_active === false ? (
                                            <span
                                              className={
                                                classes.mediaTitleHeader
                                              }
                                            >
                                              (paused)
                                            </span>
                                          ) : null}
                                        </Typography>
                                        <Typography
                                          variant="h6"
                                          style={{
                                            fontFamily: "BatonTurbo-Book",
                                            fontSize: "16px",
                                            color: "#5e686c",
                                            fontWeight: "200",
                                            marginBottom: "16px",
                                          }}
                                        >
                                          {moment(
                                            creative.published_from,
                                          ).format("DD.MM.YYYY")}{" "}
                                          -{" "}
                                          {moment(
                                            creative.published_until,
                                          ).format("DD.MM.YYYY")}
                                        </Typography>
                                        <Typography
                                          variant="h6"
                                          className={classes.dfpCreativeTitle}
                                        >
                                          {isLoggedIn
                                            ? `ID ${creative.id}: `
                                            : ""}
                                          {creative.title}
                                        </Typography>
                                        <Typography
                                          variant="body1"
                                          style={{
                                            fontSize: "14px",
                                            fontWeight: "200",
                                            maxWidth: "443px",
                                          }}
                                        >
                                          {creative.lead}
                                        </Typography>
                                      </Grid>
                                      <Grid
                                        item
                                        xs={12}
                                        sm={12}
                                        md={12}
                                        className={classes.dfpMetricsWrapper}
                                      >
                                        <Typography
                                          variant="h6"
                                          className={classes.dfpMetricsKeyBold}
                                        >
                                          CTR:{" "}
                                        </Typography>
                                        <Typography
                                          variant="h6"
                                          className={
                                            classes.dfpMetricsValueBold
                                          }
                                        >
                                          {numeral(creative.ctr).format(
                                            "0.000",
                                          )}
                                          %
                                        </Typography>
                                        <Typography
                                          variant="h6"
                                          className={classes.dfpMetricsKey}
                                        >
                                          Impressions:{" "}
                                        </Typography>
                                        <Typography
                                          variant="h6"
                                          className={classes.dfpMetricsValue}
                                        >
                                          {numeral(
                                            creative.impressions,
                                          ).format()}
                                        </Typography>{" "}
                                        <Typography
                                          variant="h6"
                                          className={classes.dfpMetricsKey}
                                        >
                                          Clicks:{" "}
                                        </Typography>
                                        <Typography
                                          variant="h6"
                                          className={classes.dfpMetricsValue}
                                        >
                                          {numeral(creative.clicks).format()}
                                        </Typography>{" "}
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                </Grid>
                              </Grid>
                            );
                          }
                          return null;
                        })}
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
          ) : null}

          {story.abTest ? (
            <ABTest abTest={story.abTest} classes={classes} />
          ) : null}
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};

export default Story;
