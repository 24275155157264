import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import axios from "axios";
import {
  Button,
  Grid,
  Tabs,
  Tab,
  Fab,
  Fade,
  Dialog,
  Box,
  TextField,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import store from "../../redux/store";
import { logOut, setErrorMessage } from "../../redux/actions/index";
import Project from "./Project";
import Search from "../Search";
import EditProject from "../EditProject";
import ProjectsSkeleton from "./ProjectsSkeleton";

const useStyles = makeStyles((theme) =>
  createStyles({
    overrides: {
      MuiInputBase: {
        root: {
          boxShadow: "none",
          borderRadius: "0px",
          borderBottomLeftRadius: "0px",
          borderBottomRightRadius: "0px",
          borderTopLeftRadius: "0px",
          borderTopRightRadius: "0px",
          backgroundColor: "#ffffff",
          border: "1px solid green",
        },
        formControl: {
          borderBottomLeftRadius: "0px",
          borderBottomRightRadius: "0px",
          borderTopLeftRadius: "0px",
          borderTopRightRadius: "0px",
        },
        input: {
          borderBottomLeftRadius: "0px",
          borderBottomRightRadius: "0px",
          borderTopLeftRadius: "0px",
          borderTopRightRadius: "0px",
        },
      },
    },
    root: {
      padding: theme.spacing(2),
      [theme.breakpoints.up("sm")]: {
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
      },
    },
    paperProject: {
      boxShadow: "0 1px 3px 0 rgba(21, 27, 32, 0.15)",
      backgroundColor: "#ffffff",
      padding: "8px 13px 8px 13px",
      "& :hover": {
        backgroundColor: "#f7f7fa",
      },
    },
    paperProjectWrapper: {
      cursor: "pointer",
      "& :hover": {
        backgroundColor: "#f7f7fa",
      },
    },
    tabContainerWrapper: {
      justifyContent: "center",
      marginBottom: "50px",
      minHeight: "44px",
      borderBottom: "2px solid rgba(72, 72, 72, 0.16)",
      [theme.breakpoints.down("lg")]: {
        marginBottom: "0px",
      },
    },
    underline: {
      "&&&:before": {
        borderBottom: "none",
      },
      "&&:after": {
        borderBottom: "none",
      },
    },
    fabIcon: {
      height: 50,
      width: 50,
    },
    fabIconNewProject: {},
    fabAddProject: {
      position: "fixed",
      bottom: theme.spacing(3),
      right: theme.spacing(3),
      zIndex: 100,
      backgroundColor: "white",
      fontSize: "",
      boxShadow:
        "0 2px 8px 0 rgba(21, 27, 32, 0.26), 0 1px 3px 0 rgba(21, 27, 32, 0.15)",

      [theme.breakpoints.up("lg")]: {
        top: "140px",
        right: "calc(50% - 440px)",
      },
    },
    modalPaper: {
      backgroundColor: "#f7f7fa",
      border: "2px solid #000",
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      maxWidth: "800px",
      outline: "none",
    },
    iconItem: {
      display: "flex",
      justifyContent: "flex-end",
    },
    projectsGridContainer: {
      [theme.breakpoints.up("lg")]: {
        paddingLeft: "10%",
        paddingRight: "10%",
      },
    },
    tabActive: {
      paddingBottom: "0px",
      minHeight: "10px",
      fontWeight: "200",
      fontSize: "16px",
      color: "#272726",
    },
    tabDefault: {
      paddingBottom: "0px",
      minHeight: "10px",
      fontWeight: "200",
      fontSize: "16px",
      color: "#5e686c",
    },
  }),
);

const TabPanel = ({ value, index, children }) => (
  <div role="tabpanel" hidden={value !== index}>
    {value === index && (
      <Grid container spacing={2}>
        {children}
      </Grid>
    )}
  </div>
);

const Projects = () => {
  const navigate = useNavigate();
  const classes = useStyles();
  const userFavorites = useSelector((state) => state.favorites);
  const [editProject, setEditProject] = useState(null);
  const [tabIndex, setTabIndex] = useState(0);
  const [projectsPageNr, setProjectsPageNr] = useState(1);
  const [projects, setProjects] = useState([]);
  const [projectsLoading, setProjectsLoading] = useState(false);
  const [favorites, setFavorites] = useState([]);
  const [favoritesLoading, setFavoritesLoading] = useState(false);
  const [myProjects, setMyProjects] = useState([]);
  const [myProjectsLoading, setMyProjectsLoading] = useState(false);
  const [searchResults, setSearchResults] = useState([]);
  const [searchResultsLoading, setSearchResultsLoading] = useState(false);

  const isEditDialogOpen = Boolean(editProject);

  const a11yProps = (index) => ({
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  });

  const handleTabChange = (_, index) => {
    setTabIndex(index);
  };

  const handleCloseEditDialog = () => {
    setEditProject(null);
  };

  const handleProjectsPageNrChange = (event) => {
    const value = event.target.value;
    if (value >= 0) {
      setProjectsPageNr(value);
    }
  };

  const handlePreviousPageClick = () => {
    if (projectsPageNr <= 1) {
      return;
    }
    setProjectsPageNr(projectsPageNr - 1);
  };

  const handleNextPageClick = () => {
    setProjectsPageNr(parseInt(projectsPageNr) + 1);
  };

  const redirectToCreateProject = () => {
    navigate("/projects/new");
  };

  const isActiveTabClass = (isActive) =>
    isActive ? classes.tabActive : classes.tabDefault;

  useEffect(() => {
    const fetchProjects = async (pageNr) => {
      try {
        setProjectsLoading(true);
        const resp = await axios.get(
          `${process.env.REACT_APP_BACKEND_BASE_URL}/v1/projects`,
          {
            withCredentials: true,
            headers: {
              Accept: "application/json",
            },
            params: {
              page: pageNr,
            },
          },
        );

        setProjects(resp.data);
        window.scrollTo(0, 0);
      } catch (err) {
        let msg = "Error getting projects";
        if (err.response) {
          if (err.response.status === 401) {
            store.dispatch(logOut());
            navigate("/login");
            return;
          }
          msg += `: ${err.response.data}`;
        }
        store.dispatch(setErrorMessage(msg));
      } finally {
        setProjectsLoading(false);
      }
    };

    fetchProjects(projectsPageNr);
  }, [projectsPageNr, navigate]);

  useEffect(() => {
    const fetchFavorites = async () => {
      try {
        setFavoritesLoading(true);
        const resp = await axios.get(
          `${process.env.REACT_APP_BACKEND_BASE_URL}/v1/me/favorites`,
          {
            withCredentials: true,
            headers: {
              Accept: "application/json",
            },
          },
        );

        setFavorites(resp.data);
      } catch (err) {
        let msg = "Error getting favorites";
        if (err.response) {
          if (err.response.status === 401) {
            store.dispatch(logOut());
            navigate("/login");
            return;
          }
          msg += `: ${err.response.data}`;
        }
        store.dispatch(setErrorMessage(msg));
      } finally {
        setFavoritesLoading(false);
      }
    };

    fetchFavorites();
  }, [userFavorites, navigate]);

  useEffect(() => {
    const fetchMyProjects = async () => {
      try {
        setMyProjectsLoading(true);
        const resp = await axios.get(
          `${process.env.REACT_APP_BACKEND_BASE_URL}/v1/me/projects`,
          {
            withCredentials: true,
            headers: {
              Accept: "application/json",
            },
          },
        );

        setMyProjects(resp.data);
      } catch (err) {
        let msg = "Error getting my projects";
        if (err.response) {
          if (err.response.status === 401) {
            store.dispatch(logOut());
            navigate("/login");
            return;
          }
          msg += `: ${err.response.data}`;
        }
        store.dispatch(setErrorMessage(msg));
      } finally {
        setMyProjectsLoading(false);
      }
    };

    fetchMyProjects();
  }, [navigate]);

  const searchProjects = async (searchTerm) => {
    if (!searchTerm) {
      return;
    }

    try {
      setSearchResultsLoading(true);
      const resp = await axios.get(
        `${process.env.REACT_APP_BACKEND_BASE_URL}/v1/projects`,
        {
          withCredentials: true,
          headers: {
            Accept: "application/json",
          },
          params: {
            search: searchTerm,
          },
        },
      );

      setSearchResults(resp.data);
    } catch (err) {
      let msg = "Error searching projects";
      if (err.response) {
        if (err.response.status === 401) {
          store.dispatch(logOut());
          navigate("/login");
          return;
        }
        msg += `: ${err.response.data}`;
      }
      store.dispatch(setErrorMessage(msg));
    } finally {
      setSearchResultsLoading(false);
    }
  };

  return (
    <Grid container spacing={2} className={classes.projectsGridContainer}>
      <Dialog
        open={isEditDialogOpen}
        closeAfterTransition
        onClose={handleCloseEditDialog}
        maxWidth="md"
      >
        <Fade in={isEditDialogOpen}>
          <div className={classes.modalPaper}>
            <EditProject
              project={editProject}
              handleCloseDialog={handleCloseEditDialog}
            />
          </div>
        </Fade>
      </Dialog>

      <Fab
        className={classes.fabAddProject}
        size="large"
        onClick={redirectToCreateProject}
      >
        <Box style={{ display: "flex" }}>
          <img
            src="/img/icons/plus.svg"
            alt="Create project"
            style={{ width: "35px", height: "35px" }}
          />
        </Box>
      </Fab>

      <Grid item xs={12} sm={12} md={12}>
        <Grid container spacing={2}>
          <Search
            setTabIndexFn={setTabIndex}
            triggerSearchFn={searchProjects}
          />
        </Grid>
      </Grid>
      <Grid item xs={12} sm={12} md={12}>
        <Tabs
          value={tabIndex}
          onChange={handleTabChange}
          centered
          className={classes.tabContainerWrapper}
          TabIndicatorProps={{
            style: {
              backgroundColor: "#223647",
              height: "4px",
            },
          }}
          aria-label="projects tabs"
        >
          <Tab
            label="Favorites"
            className={isActiveTabClass(tabIndex === 0)}
            style={{ textTransform: "none" }}
            {...a11yProps(0)}
          />
          <Tab
            label="My reports"
            className={isActiveTabClass(tabIndex === 1)}
            style={{ textTransform: "none" }}
            {...a11yProps(1)}
          />
          <Tab
            label="All"
            className={isActiveTabClass(tabIndex === 2)}
            style={{ textTransform: "none" }}
            {...a11yProps(2)}
          />
          <Tab
            label="Search Results"
            className={isActiveTabClass(tabIndex === 3)}
            style={{ textTransform: "none" }}
            {...a11yProps(3)}
          />
        </Tabs>
      </Grid>
      <Grid item xs={12} sm={12} md={12}>
        <TabPanel value={tabIndex} index={0}>
          {favorites.length > 0 ? (
            favorites.map((p) => (
              <Project
                key={p._id}
                project={p}
                classes={classes}
                setEditProjectFn={setEditProject}
              />
            ))
          ) : (
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={12}>
                <div style={{ paddingTop: 18, textAlign: "center" }}>
                  {favoritesLoading ? <ProjectsSkeleton /> : "No favorites"}
                </div>
              </Grid>
            </Grid>
          )}
        </TabPanel>
        <TabPanel value={tabIndex} index={1}>
          {myProjects.length > 0 ? (
            myProjects.map((p) => (
              <Project
                key={p._id}
                project={p}
                classes={classes}
                setEditProjectFn={setEditProject}
              />
            ))
          ) : (
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={12}>
                <div style={{ textAlign: "center" }}>
                  {myProjectsLoading ? <ProjectsSkeleton /> : "No reports"}
                </div>
              </Grid>
            </Grid>
          )}
        </TabPanel>

        <TabPanel value={tabIndex} index={2}>
          {projects.length > 0 ? (
            projects.map((p) => (
              <Project
                key={p._id}
                project={p}
                classes={classes}
                setEditProjectFn={setEditProject}
              />
            ))
          ) : (
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={12}>
                <div style={{ textAlign: "center" }}>
                  {projectsLoading ? <ProjectsSkeleton /> : "No reports"}
                </div>
              </Grid>
            </Grid>
          )}
          <Grid container spacing={2} style={{ marginTop: "2em" }}>
            <Grid item xs={12} sm={4} md={4}>
              <Button
                variant="outlined"
                fullWidth
                size="large"
                disabled={projectsPageNr <= 1}
                onClick={handlePreviousPageClick}
              >
                &lt; Previous
              </Button>
            </Grid>
            <Grid item xs={12} sm={4} md={4}>
              <TextField
                id="outlined-basic"
                label="Page"
                variant="outlined"
                size="small"
                fullWidth
                inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
                value={projectsPageNr}
                onChange={handleProjectsPageNrChange}
              />
            </Grid>
            <Grid item xs={12} sm={4} md={4}>
              <Button
                variant="outlined"
                size="large"
                fullWidth
                onClick={handleNextPageClick}
              >
                Next &gt;
              </Button>
            </Grid>
          </Grid>
        </TabPanel>

        <TabPanel value={tabIndex} index={3}>
          {searchResults.length > 0 ? (
            <Grid container spacing={2}>
              {searchResults.map((p) => (
                <Project
                  key={p._id}
                  project={p}
                  classes={classes}
                  setEditProjectFn={setEditProject}
                />
              ))}
            </Grid>
          ) : (
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={12}>
                <div style={{ textAlign: "center" }}>
                  {searchResultsLoading ? (
                    <ProjectsSkeleton />
                  ) : (
                    "No results found"
                  )}
                </div>
              </Grid>
            </Grid>
          )}
        </TabPanel>
      </Grid>
    </Grid>
  );
};

export default Projects;
