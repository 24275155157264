import { useEffect } from "react";
import { Navigate, useNavigate, useLocation } from "react-router-dom";
import store from "../redux/store";
import { logIn } from "../redux/actions/index";

const Auth = () => {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    store.dispatch(logIn());

    navigate("/");
  }, [navigate, location.search]);

  return <Navigate to="/" />;
};

export default Auth;
