const colors = [
  "#376A93",
  "#44ADB3",
  "#299381",
  "#2FB9D8",
  "#B9D373",
  "#586D72",
  "#582E2E",
  "#935238",
  "#3A2247",
  "#6C3089",
  "#641746",
  "#D1371D",
  "#ED7389",
  "#F9B25E",
  "#E9AB8A",
  "#F6CA41",
  "#AC8C82",
  "#936B27",
];

export default colors;
