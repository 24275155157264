import { combineReducers } from "redux";
import isLoggedIn from "./isLoggedIn";
import isAdmin from "./isAdmin";
import favorites from "./favorites";
import settings from "./settings";
import errorMessage from "./errorMessage";

export default combineReducers({
  isLoggedIn,
  isAdmin,
  favorites,
  settings,
  errorMessage,
});
