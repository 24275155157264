const favorites = (state = [], action) => {
  switch (action.type) {
    case "SET_FAVORITES":
      return action.favorites;
    case "LOG_OUT":
      return [];
    default:
      return state;
  }
};

export default favorites;
