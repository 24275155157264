import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { Grid, Typography, Box, IconButton, Paper } from "@mui/material";
import axios from "axios";
import moment from "moment";
import store from "../../redux/store";
import {
  logOut,
  setUserFavorites,
  setErrorMessage,
} from "../../redux/actions/index";

const Project = ({ classes, project, setEditProjectFn }) => {
  const navigate = useNavigate();
  const userFavorites = useSelector((state) => state.favorites);

  const isFavorite = userFavorites.includes(project._id);

  const favProject = async (event, id) => {
    event.stopPropagation();

    try {
      const resp = await axios.post(
        `${process.env.REACT_APP_BACKEND_BASE_URL}/v1/projects/fav/${id}`,
        {},
        {
          withCredentials: true,
          headers: {
            Accept: "application/json",
          },
        },
      );

      store.dispatch(setUserFavorites(resp.data.favorites));
    } catch (err) {
      let msg = "Error favoring project";
      if (err.response) {
        if (err.response.status === 401) {
          store.dispatch(logOut());
          navigate("/login");
          return;
        }
        msg += `: ${err.response.data}`;
      }
      store.dispatch(setErrorMessage(msg));
    }
  };

  const handleEditClick = (event, project) => {
    event.stopPropagation();
    setEditProjectFn(project);
  };

  const handleProjectClick = (id) => {
    navigate(`/reports/${id}`);
  };

  return (
    <Grid item xs={12} sm={12} md={12} className={classes.paperProjectWrapper}>
      <Paper
        className={classes.paperProject}
        elevation={0}
        variant="outlined"
        square
        onClick={() => {
          handleProjectClick(project._id);
        }}
      >
        <Grid container spacing={1}>
          <Grid item xs={12} sm={12} md={12} style={{ paddingBottom: "0px" }}>
            <Grid
              container
              spacing={1}
              style={{
                justifyContent: "space-between",
              }}
            >
              <Grid
                item
                xs={10}
                sm={10}
                md={10}
                className={classes.projectTitleWrapper}
              >
                <Typography variant="h6" style={{ fontSize: 18 }}>
                  {project.abnumber ? `${project.abnumber} /` : null}{" "}
                  {project.name
                    ? `${project.name} /`
                    : project.isDailyDeal
                    ? `Daily Deal /`
                    : null}{" "}
                  {project.client || null}
                </Typography>
              </Grid>
              <Grid item xs={2} sm={2} md={1} className={classes.iconItem}>
                <Box component="span" m={0}>
                  <IconButton
                    size="large"
                    color={isFavorite ? `primary` : "default"}
                    onClick={(e) => favProject(e, project._id)}
                    style={{ padding: "2px" }}
                  >
                    {isFavorite ? (
                      <img
                        src="/img/icons/Star--filled.svg"
                        style={{ width: "23.4px", height: "23.4px" }}
                        alt="Is favorite"
                      />
                    ) : (
                      <img
                        src="/img/icons/Star.svg"
                        style={{ width: "23.4px", height: "23.4px" }}
                        alt="Is not favorite"
                      />
                    )}
                  </IconButton>
                </Box>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} md={12} style={{ paddingTop: "4px" }}>
            <Grid
              container
              spacing={1}
              style={{
                justifyContent: "space-between",
              }}
            >
              <Grid item xs={10} sm={10} md={10}>
                <Grid container spacing={1}>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    style={{ paddingTop: "0px", paddingBottom: "0px" }}
                  >
                    <Typography
                      component="p"
                      style={{
                        fontSize: "14px",
                        fontWeight: "200",
                        color: "#5e686c",
                        fontFamily: "BatonTurbo-Book",
                      }}
                    >
                      Created by {project.owner ? project.owner.name : ""}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    style={{ paddingTop: "0px", paddingBottom: "0px" }}
                  >
                    <Typography
                      component="p"
                      style={{
                        fontSize: "14px",
                        fontWeight: "200",
                        color: "#5e686c",
                        fontFamily: "BatonTurbo-Book",
                      }}
                    >
                      {moment(project.daterangeFrom).format("DD.MM.YYYY")} -{" "}
                      {moment(project.daterangeTo).format("DD.MM.YYYY")}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={1} sm={1} md={1} className={classes.iconItem}>
                <Box component="span" m={0}>
                  <IconButton
                    size="large"
                    style={{ padding: "2px" }}
                    onClick={(e) => {
                      handleEditClick(e, project);
                    }}
                  >
                    <Box style={{ display: "flex" }}>
                      <img
                        src="/img/icons/edit-2.svg"
                        alt="Edit project"
                        style={{ width: "23.4px", height: "23.4px" }}
                      />
                    </Box>
                  </IconButton>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  );
};

export default Project;
