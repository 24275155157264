import { Paper, Grid, Typography, Box, Tooltip } from "@mui/material";
import numeral from "numeral";

const BigNumber = ({
  title,
  description,
  value,
  unit,
  decimals,
  color,
  size,
}) => {
  if (!value) {
    return null;
  }

  let format;
  if (decimals && !isNaN(decimals)) {
    format = "0." + Array(decimals + 1).join("0");
  }

  return (
    <Tooltip title={description || ""} placement="top">
      <Grid
        item
        xs={6}
        sm={3}
        md={3}
        style={{
          textAlign: "center",
        }}
      >
        <Paper
          variant="outlined"
          square
          style={{
            backgroundColor: color ? color : "transparent",
            color: color ? "white" : "black",
            padding: `${size === "sm" ? "10px" : "15px"} 5px`,
            borderWidth: "2px",
            borderColor: color ? "white" : "black",
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12}>
              <Box textAlign="right">
                <Typography
                  variant="body1"
                  style={{
                    color: color ? "white" : "inherit",
                    fontSize: size === "sm" ? "14px" : "18px",
                    fontWeight: "200",
                    textAlign: "center",
                  }}
                >
                  {title}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={12} style={{ paddingTop: "1px" }}>
              <Box textAlign="right">
                <Typography
                  variant="h6"
                  style={{
                    textAlign: "center",
                    fontSize: "26px",
                  }}
                >
                  {numeral(value).format(format)}
                  {unit || null}
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Tooltip>
  );
};

export default BigNumber;
