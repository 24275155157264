import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import axios from "axios";
import moment from "moment";
import Spinner from "react-spinkit";
import {
  Paper,
  Grid,
  Typography,
  IconButton,
  Box,
  Fab,
  Dialog,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Button,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import { DatePicker } from "@mui/x-date-pickers";
import TextField from "@mui/material/TextField";
import numeral from "numeral";
import BarDemographics from "./Graphs/BarDemographics";
import BarStackedTS from "./Graphs/BarStackedTS";
import DataExplorer from "./DataExplorer";
import Summary from "./stories/Summary";
import Stories from "./stories/Stories";
import Longform from "./longforms/Longform";
import LongformSummary from "./longforms/LongformSummary";
import SocialMediaStories from "./socialMedia/Stories";
import SocialMediaSummary from "./socialMedia/Summary";
import EditProject from "../EditProject";
import BigNumber from "./BigNumber";
import {
  nestByStoryID,
  rollupTimeSeriesBar,
  demographicsBar,
  campaignSummaryData,
  rollupContentHubOverview,
  rollupContentHubDetails,
} from "./D3Functions";
import store from "../../redux/store";
import { logOut, setUserFavorites } from "../../redux/actions/index";
import kpiVocabulary from "../../vocabulary/kpis";

const useStyles = makeStyles((theme) =>
  createStyles({
    paperGraph: {
      padding: theme.spacing(1),
      marginTop: "40px",
      backgroundColor: "#f7f7fa",
      border: "none",
    },
    storyPanelExpanded: {
      width: "100%",
      maxWidth: "840px",
      color: "#223647",
      [theme.breakpoints.down("sm")]: {},
    },
    storyPanelDFP: {
      borderTop: "2px solid #f7f7fa",
      boxShadow: "none",
      width: "100%",
      marginTop: "10px",
      marginBottom: "10px",
    },
    storiesContainer: {
      paddingLeft: "8px",
      paddingRight: "8px",
      [theme.breakpoints.down("sm")]: {
        paddingLeft: "0px",
        paddingRight: "0px",
      },
    },
    centeredTabs: {
      paddingLeft: "0px !important",
      paddingRight: "0px !important",
    },
    fabEdit: {
      position: "fixed",
      bottom: theme.spacing(3),
      right: theme.spacing(3),
      zIndex: 100,
      backgroundColor: "white",
      fontSize: "",
      color: "#223647",
      boxShadow:
        "0 2px 8px 0 rgba(21, 27, 32, 0.26), 0 1px 3px 0 rgba(21, 27, 32, 0.15)",
      [theme.breakpoints.up("lg")]: {
        top: "125px",
        right: "calc(50% - 500px)",
      },
      "&:hover": {
        backgroundColor: "#f7f7fa",
      },
    },
    fabGoBack: {
      position: "fixed",
      bottom: theme.spacing(3),
      left: theme.spacing(3),
      zIndex: 100,
      backgroundColor: "white",
      fontSize: "",
      color: "#223647",
      boxShadow:
        "0 2px 8px 0 rgba(21, 27, 32, 0.26), 0 1px 3px 0 rgba(21, 27, 32, 0.15)",
      [theme.breakpoints.up("lg")]: {
        top: "125px",
        left: "calc(50% - 500px)",
      },
      "&:hover": {
        backgroundColor: "#f7f7fa",
      },
    },
    dateControl: {
      transform: "translate(0, 4px)",
      "& > div > input": {
        boxShadow: "none",
        paddingTop: "0px",
        paddingBottom: "0px",
        maxWidth: "90px",

        fontSize: "18px",
        height: "24px",
        color: "#5e686c",
      },
    },
    modalPaper: {
      backgroundColor: "#f7f7fa",
      border: "2px solid #000",
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      outline: "none",
    },
    gridNoMargin: {
      margin: 0,
    },
    outboundClickUrlContainer: {
      "&&&": {
        paddingLeft: "0px",
        paddingRight: "0px",
        paddingBottom: "0px",
        paddingTop: "0px",
        overflowWrap: "break-word",
        fontWeight: "400",
      },
    },
    outboundClickValueContainer: {
      "&&&": {
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
        fontWeight: "700",
      },
    },
    clickTableRow: {
      borderBottom: "1px solid #e4e4e4",
      "&&&": {
        paddingLeft: "0px",
        paddingReight: "0px",
      },
    },
    clickTableRowInner: {
      paddingTop: "8px",
      paddingBottom: "8px",
      paddingLeft: "0px",
      paddingReight: "0px",
      margin: "0px",
      width: "100%",
    },
    overviewRow: {
      paddingTop: "0px!important",
      paddingBottom: "0px!important",
      [theme.breakpoints.down("sm")]: {
        "&&&": {
          paddingLeft: "16px",
          paddingRight: "16px",
        },
      },
    },
    overviewTypography: {
      lineHeight: "1.6",
    },
    overviewKey: {
      display: "inline-block",
      fontSize: "18px",
      fontWeight: "200",
      color: "#5e686c",
      lineHeight: "0.94",
      [theme.breakpoints.down("sm")]: {
        fontSize: "14px",
      },
    },
    overviewValue: {
      display: "inline-block",
      fontStyle: "bold",
      fontSize: "20px",
      paddingLeft: "5px",
      [theme.breakpoints.down("sm")]: {
        fontSize: "18px",
      },
    },
    overviewKpiWrapperDesktop: {
      marginTop: "30px",
      [theme.breakpoints.down("sm")]: {
        display: "none",
      },
    },
    overviewKpiWrapperMobile: {
      marginTop: "30px",

      display: "none",
      [theme.breakpoints.down("sm")]: {
        display: "flex",
        "&&&": {
          paddingLeft: "16px",
          paddingRight: "16px",
        },
      },
    },
    loadingWrapper: {
      width: "80px",
      marginTop: "40vh",
      marginLeft: "calc(50% - 40px)",
      [theme.breakpoints.down("sm")]: {
        marginTop: "50%",
      },
    },
    dateField: {
      "& input": {
        boxShadow: "none",
        width: "100%",
      },
    },
    graphContainer: {
      paddingLeft: "10%",
      paddingRight: "10%",
    },
    graphTitleContainer: {
      [theme.breakpoints.down("sm")]: {
        alignSelf: "center",
      },
    },
    graphTitle: {
      color: "#223647",
      fontSize: "22px",
      fontWeight: "700",
      [theme.breakpoints.down("sm")]: {
        fontSize: "18px",
      },
    },
    storyInfosWrapper: {
      display: "flex",
      flexDirection: "row",
      [theme.breakpoints.down("sm")]: {
        flexDirection: "column",
      },
    },
    storyInfos: {
      color: "#262627",
    },
    storyInfosKey: {
      fontWeight: "bold",
      marginRight: "5px",
      fontSize: "16px",
    },
    storyInfosValue: {
      marginRight: "10px",
      fontWeight: "200",
    },
    dfpCreativeWrapper: {
      marginBottom: "37px",
      [theme.breakpoints.down("sm")]: {
        marginBottom: "14px",
      },
    },
    dfpCreativeTitle: {
      fontSize: "18px",
      color: "#262627",
      lineHeight: "1.11",
      maxWidth: "443px",
      marginBottom: "8px",
      [theme.breakpoints.down("sm")]: {
        fontSize: "14px",
      },
    },
    dfpMetricsKey: {
      color: "#5e686c",
      fontSize: "16px",
      display: "inline-block",
      fontFamily: "BatonTurbo-Book",
      lineHeight: "1.06",
      fontWeight: "normal",
      marginRight: "6px",
      [theme.breakpoints.down("sm")]: {
        fontSize: "14px",
      },
    },
    dfpMetricsValue: {
      color: "#5e686c",
      fontSize: "16px",
      display: "inline-block",
      marginRight: "25px",
      fontFamily: "BatonTurbo-Book",
      lineHeight: "1.06",
      fontWeight: "normal",
      [theme.breakpoints.down("sm")]: {
        fontSize: "14px",
      },
    },
    dfpMetricsKeyBold: {
      color: "#5e686c",
      fontSize: "16px",
      display: "inline-block",
      fontFamily: "BatonTurbo-Book",
      lineHeight: "1.06",
      fontWeight: "700",
      marginRight: "6px",
      [theme.breakpoints.down("sm")]: {
        fontSize: "14px",
      },
    },
    dfpMetricsValueBold: {
      color: "#5e686c",
      fontSize: "16px",
      display: "inline-block",
      marginRight: "25px",
      fontFamily: "BatonTurbo-Book",
      lineHeight: "1.06",
      fontWeight: "700",
      [theme.breakpoints.down("sm")]: {
        fontSize: "14px",
      },
    },
    dfpMetricsWrapper: {
      marginTop: "20px",
      [theme.breakpoints.down("sm")]: {
        marginTop: "4px",
      },
    },
    accordionSummaryInner: {
      padding: "0px 8px",
    },
    accordionSummaryDetails: {
      padding: "0px 8px 30px 8px",
    },
    calendarIconAdornment: {
      "& > button": {
        padding: "0px",
      },
      "& > input": {
        maxWidth: "50px",
      },
    },
    ogAccordionSummaryText: {
      color: "#376a93",
      [theme.breakpoints.down("sm")]: {
        fontSize: "16px",
      },
    },
    teaserAccordionSummaryText: {
      color: "#376a93",
      [theme.breakpoints.down("sm")]: {
        fontSize: "16px",
      },
    },
    ogDateRange: {
      color: "#5e686c",
      fontSize: "16px",
      fontWeight: "200",
      lineHeight: "1.06",
      marginBottom: "7px",
      [theme.breakpoints.down("sm")]: {
        fontSize: "14px",
      },
    },
    ogTitle: {
      fontSize: "18px",
      color: "#262627",
      lineHeight: "1.11",
      maxWidth: "443px",
      marginBottom: "6px",
      [theme.breakpoints.down("sm")]: {
        fontSize: "14px",
      },
    },
    ogDescription: {
      fontSize: "14px",
      fontWeight: "200",
      maxWidth: "443px",
      color: "#262627",
      [theme.breakpoints.down("sm")]: {
        fontSize: "14px",
      },
    },
    datePickerIcon: {
      width: "23.4px",
      height: "23.4px",
      [theme.breakpoints.down("sm")]: {
        width: "14px",
        height: "14px",
      },
    },
    graphToggleButtonContainer: {
      display: "none",
      [theme.breakpoints.down("sm")]: {
        display: "flex",
        justifyContent: "flex-end",
      },
    },
    mediaTitlesSelected: {
      fontWeight: "200",
      color: "#223647",
      display: "inline-block",
      marginRight: "15px",
    },
    mediaTitlesNotSelected: {
      fontWeight: "200",
      color: "#aeb5bd",
      display: "inline-block",
      marginRight: "15px",
    },
    mediaTitleHeader: {
      fontSize: "16px",
      color: "#223647",
      marginRight: "15px",
    },
    imgBoxSquare: {
      width: "100%",
      position: "relative",
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      paddingBottom: "100%",
    },
    imgBoxSquareInner: {
      position: "absolute",
      top: "0",
      bottom: "0",
      width: "100%",
      height: "100%",
    },
    contentHubOverviewTitle: {
      fontSize: "18px",
      color: "#262627",
      lineHeight: "1.11",
      marginBottom: "6px",
      [theme.breakpoints.down("sm")]: {
        fontSize: "14px",
      },
    },
    contentHubOverviewSubtitle: {
      fontSize: "16px",
      color: "#262627",
      lineHeight: "1.11",
      marginBottom: "6px",
      [theme.breakpoints.down("sm")]: {
        fontSize: "14px",
      },
    },
  }),
);

const Loading = ({ classes }) => (
  <Grid
    container
    spacing={2}
    style={{
      backgroundColor: "#f7f7fa",
      padding: "0px",
      margin: "0px",
    }}
  >
    <Grid item xs={12} sm={12} md={12}>
      <Spinner
        className={classes.loadingWrapper}
        name="pacman"
        color="#223647"
      />
    </Grid>
  </Grid>
);

const ErrorMessage = ({ message, errorType }) => {
  const navigate = useNavigate();

  const handleLogInClick = () => {
    navigate("/login");
  };

  const handleLoadAsGuestClick = () => {
    window.location.reload();
  };

  return (
    <Grid
      container
      spacing={2}
      style={{
        backgroundColor: "#f7f7fa",
        padding: "0px",
        margin: "0px",
      }}
    >
      <Grid item xs={12} sm={12} md={12}>
        <Box
          sx={{
            width: "380px",
            marginTop: {
              xs: "50%",
              md: "40vh",
            },
            padding: "28px",
            textAlign: "center",
            marginLeft: "calc(50% - 140px)",
            backgroundColor: "white",
            boxShadow:
              "0 2px 8px 0 rgba(21, 27, 32, 0.26), 0 1px 3px 0 rgba(21, 27, 32, 0.15)",
          }}
        >
          {errorType === "auth" ? (
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={12}>
                You got logged out
              </Grid>
              <Grid item xs={12} sm={12} md={12}>
                <Button onClick={handleLogInClick}>Log in</Button>
                <Button onClick={handleLoadAsGuestClick}>Load as guest</Button>
              </Grid>
            </Grid>
          ) : (
            message || "Unknown error"
          )}
        </Box>
      </Grid>
    </Grid>
  );
};

const generateUrlStoryObjects = (projectData, titleData) => {
  const allStories = [];

  const titles =
    titleData && titleData.data && titleData.data.rowObjects
      ? titleData.data.rowObjects
      : [];

  if (Array.isArray(projectData.urls)) {
    projectData.urls.forEach((e) => {
      const id = e.replace("https://", "");
      let title = "n/a";
      const titleCandidates = titles.filter((t) => t.storyID === id);

      if (Array.isArray(titleCandidates)) {
        // for case multiple candidates
        const sorted = titleCandidates.sort(
          (a, b) =>
            parseFloat(b.screenPageViews) - parseFloat(a.screenPageViews),
        );

        if (sorted && sorted[0] && sorted[0].dimension2) {
          title = sorted[0].dimension2;
        }
      }

      const urlStory = {
        id,
        name: title,
        reportType: "URL",
      };

      // extract story infos from URL && append if existing
      const extractionPattern =
        /(cp\.)(.*)(\.ch\/)(\w{2})(\/stories\/)(\d{2,8})/i;
      const patternMatch = e.match(extractionPattern);

      if (Array.isArray(patternMatch) && patternMatch.length === 7) {
        // we have data to extract from URL
        urlStory.links = {};
        urlStory.links[patternMatch[2]] = {};
        urlStory.links[patternMatch[2]][patternMatch[4]] = e;
      }

      allStories.push(urlStory);
    });
  }

  return allStories;
};

const Report = () => {
  const classes = useStyles();
  const params = useParams();
  const userFavorites = useSelector((state) => state.favorites);
  const isLoggedIn = useSelector((state) => state.isLoggedIn);
  const isAdmin = useSelector((state) => state.isAdmin);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [errorType, setErrorType] = useState(null);
  const [projectData, setProjectData] = useState({});
  const [reportData, setReportData] = useState(null);
  const [reportOverviewData, setReportOverviewData] = useState({});
  const [storySelection, setStorySelection] = useState([]);
  const [storyKPIs, setStoryKPIs] = useState(null);
  const [campaignKPIs, setCampaignKPIs] = useState(null);
  const [contentHubOverview, setContentHubOverview] = useState(null);
  const [contentHubDetails, setContentHubDetails] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [timeSeriesBarData, setTimeSeriesBarData] = useState({});
  const [dataDemographicsBar, setDataDemographicsBar] = useState([]);
  const [isFavorite, setIsFavorite] = useState(false);
  const [dateFrom, setDateFrom] = useState(null);
  const [dateTo, setDateTo] = useState(null);
  const [showPageviewGraph, setShowPageviewGraph] = useState(true);
  const [showDemographicsGraph, setShowDemographicsGraph] = useState(true);

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  const handleOpenDialog = () => {
    setDialogOpen(true);
  };

  const getReport = async (id, options = { useProjectDateRange: false }) => {
    setIsLoading(true);

    const { useProjectDateRange } = options;

    try {
      const resp = await axios.get(
        `${process.env.REACT_APP_BACKEND_BASE_URL}/v1/reports/${id}`,
        {
          params: {
            dateFrom:
              dateFrom && !useProjectDateRange
                ? encodeURI(moment(dateFrom).format("YYYY-MM-DD"))
                : null,
            dateTo:
              dateTo && !useProjectDateRange
                ? encodeURI(moment(dateTo).format("YYYY-MM-DD"))
                : null,
          },
          withCredentials: true,
          headers: {
            Accept: "application/json",
          },
        },
      );

      const { data } = resp;

      const overviewDataAccumulator = {};
      let contentHubStories;
      let dailyDealStories;

      if (data.project && data.project.projectType === "storyLinkChoice") {
        // generate some decent story object that works similarly to CRASS stories
        const titleData = data.gaRaw.find(
          (e) => e.custom_name === "query_title",
        );
        const urlStoryObjects = generateUrlStoryObjects(
          data.project,
          titleData,
        );
        setStorySelection(urlStoryObjects);
      }

      if (
        data.project &&
        data.project.projectType === "abnumberChoice" &&
        !data.project.isContentHub &&
        !data.project.isDailyDeal
      ) {
        setStorySelection(
          data.CRASS && Array.isArray(data.CRASS.stories)
            ? data.CRASS.stories
            : [],
        );
      } else if (
        data.project &&
        data.project.projectType === "abnumberChoice" &&
        data.project.isContentHub
      ) {
        // unnest stories from campaign
        // make sure we have campaigns
        contentHubStories = [];
        if (Array.isArray(data.CRASS.campaigns)) {
          data.CRASS.campaigns.forEach((c) => {
            if (Array.isArray(c.stories)) {
              c.stories.forEach((s) => {
                contentHubStories.push(s);
              });
            }
          });
        }

        setStorySelection(
          Array.isArray(contentHubStories) ? contentHubStories : [],
        );
      } else if (
        data.project &&
        data.project.projectType === "abnumberChoice" &&
        data.project.isDailyDeal
      ) {
        dailyDealStories = [];
        if (
          Array.isArray(data.CRASS.stories) &&
          data.CRASS.stories.length > 0
        ) {
          data.CRASS.stories.forEach((dds) => {
            const storyModified = dds;

            // assumption: one line_item, one language
            if (Array.isArray(dds.line_items) && dds.line_items.length === 1) {
              storyModified.id = `daily_${dds.line_items[0].id}`;
            }

            dailyDealStories.push(storyModified);
          });
        }
        setStorySelection(dailyDealStories);
      }
      if (data && data.project) {
        setProjectData({
          ...data.project,
          daterangeFrom: data.daterange.from,
          daterangeTo: data.daterange.to,
        });

        overviewDataAccumulator.name = data.project.name || undefined;
        overviewDataAccumulator.client = data.project.client || undefined;

        setDateFrom(data.project.daterangeFrom);
        setDateTo(data.project.daterangeTo);
      }

      if (data && data.CRASS) {
        overviewDataAccumulator.name = data.CRASS.name;
        if (!overviewDataAccumulator.client) {
          overviewDataAccumulator.client = data.CRASS.customer
            ? data.CRASS.customer.name
            : "n/a";
        }
      }

      // override of customer & campaign for content hub case

      if (data.project && data.project.isContentHub) {
        overviewDataAccumulator.name = data.project.name
          ? data.project.name
          : "n/a";
        overviewDataAccumulator.client = data.project.client
          ? data.project.client
          : "n/a";
      }

      setReportOverviewData({ ...overviewDataAccumulator });
      if (data && data.gaRaw) {
        let rows = nestByStoryID(
          data.gaRaw[0] && data.gaRaw[0].data
            ? data.gaRaw[0].data.rowObjects
            : [],
        );

        // fix linkout campaigns by pretending to have rows
        // replace rows with story mapping if it is linkout
        if (
          data.project &&
          (data.project.isLinkoutCampaign || data.project.isDailyDeal) &&
          Array.isArray(rows) &&
          rows.length === 0 &&
          Array.isArray(data.CRASS.stories) &&
          data.CRASS.stories.length > 0
        ) {
          rows = data.CRASS.stories.map((e) => ({ key: e.id }));

          const rowsLinkout = [];

          data.CRASS.stories.forEach((e) => {
            const actualID = e.id;

            if (Array.isArray(e.languages) && e.languages.length > 0) {
              e.languages.forEach((l) => {
                rowsLinkout.push({ key: `${actualID}-${l.locale}` });
              });
            }
          });

          // toggle between rows // rows linkout for testing
          rows = rowsLinkout;
        }

        const eventData = data.gaRaw.find(
          (e) => e.custom_name === "query_events",
        );
        const events = eventData?.data?.rowObjects;

        if (
          Array.isArray(rows) &&
          data.CRASS &&
          (Array.isArray(data.CRASS.stories) ||
            Array.isArray(contentHubStories))
        ) {
          // differentiate between CRASS and ContentHub
          let storiesToUse;

          if (Array.isArray(data.CRASS.stories)) {
            storiesToUse = data.CRASS.stories;
          } else if (Array.isArray(contentHubStories)) {
            storiesToUse = contentHubStories;
          } else {
            storiesToUse = [];
          }

          rows.forEach((e) => {
            const storyId = String(e.key);

            const languagePattern = /(-)(\w{2})$/;
            // const storyIdNoLanguage = storyId.replace(languagePattern, "");
            const languageMatch = storyId.match(languagePattern);
            const storyLanguage =
              languageMatch && languageMatch.length === 3
                ? languageMatch[2]
                : undefined;

            const story = storiesToUse.find(
              (s) => String(storyId) === String(`${s.id}-${storyLanguage}`),
            );

            if (story) {
              story.language = storyLanguage; // language;

              // append isLinkoutCampaign state
              story.isLinkoutCampaign = !!(
                data.project && data.project.isLinkoutCampaign
              );

              story.isDailyDeal = !!(data.project && data.project.isDailyDeal);

              story.isContentHub = !!(
                data.project && data.project.isContentHub
              );

              if (
                story.adserver_order_id &&
                data.DFP &&
                Array.isArray(data.DFP.lineItems)
              ) {
                e.adserver_order_id = story.adserver_order_id;

                e.lineItem = data.DFP.lineItems.find(
                  (l) => l.orderId === story.adserver_order_id,
                );

                e.lineItemsFiltered = data.DFP.lineItems.filter(
                  (l) => l.orderId === story.adserver_order_id,
                );

                e.creativesFiltered = data.DFP.creatives.filter((l) => {
                  const crassCreativeArray = Array.isArray(story.creatives)
                    ? story.creatives
                    : [];

                  const crassCreative = crassCreativeArray.find(
                    (c) => c.adserver_id === l.creativeId,
                  );
                  if (!crassCreative) {
                    return false;
                  }

                  return (
                    l.orderId === story.adserver_order_id &&
                    crassCreative.language_code === storyLanguage
                  );
                });
              }

              if (
                Array.isArray(story.creatives) &&
                data.DFP &&
                Array.isArray(data.DFP.creatives)
              ) {
                story.creativesWithDFP = [];
                story.creatives.forEach((s) => {
                  const creativeId = s.adserver_id;

                  // this will only find one line item (?)
                  const dfpCreativeData = data.DFP.creatives.find(
                    (c) => creativeId === c.creativeId,
                  );

                  const dfpCreativeDataFiltered = data.DFP.creatives.filter(
                    (c) => creativeId === c.creativeId,
                  );

                  const totals = {
                    impressions: 0,
                    clicks: 0,
                  };

                  dfpCreativeDataFiltered.forEach((c) => {
                    if (c.impressions) {
                      totals.impressions += c.impressions;
                    }
                    if (c.clicks) {
                      totals.clicks += c.clicks;
                    }
                  });

                  // trying to hack CRASS start & end date into this mess because DFP dates are off
                  const datesCRASS = {
                    published_from: null,
                    published_until: null,
                  };
                  if (Array.isArray(story.line_items)) {
                    const lineItemFound = story.line_items.find((li) => {
                      return (
                        s.line_item_id === li.id &&
                        s.line_item_adserver_id === li.adserver_id
                      );
                    });

                    if (lineItemFound) {
                      datesCRASS.published_from = lineItemFound.published_from;
                      datesCRASS.published_until =
                        lineItemFound.published_until;

                      s.media_title_codes = s.media_title_codes.map((mt) => {
                        if (mt === "20minuten") {
                          for (const p of lineItemFound.positions) {
                            if (
                              p.includes("wettbewerbe") ||
                              p.includes("concours-et-jeux")
                            ) {
                              return `${mt}-wettbewerb`;
                            }
                          }
                          return `${mt}-front`;
                        }

                        return mt;
                      });
                    }
                  }

                  const withDFP = {
                    ...s,
                    ...dfpCreativeData,
                    ...totals,
                    ...datesCRASS,
                  };

                  if (dfpCreativeData) {
                    story.creativesWithDFP.push(withDFP);
                  }
                });
              }
            }
          });
        }

        // append events
        rows.forEach((r) => {
          const storyEvents = events
            ? events.filter((e) => r.key === e.storyID)
            : [];
          r.events = storyEvents;
        });
        rows = rows.filter(
          (r) => r.creativesFiltered && r.creativesFiltered.length > 0,
        );

        // now that we filtered rows, extract IDs for data to be displayed in graph
        const idsToDisplay = rows.map((row) => row.key);

        const timeSeriesBar = rollupTimeSeriesBar(
          data.gaRaw[0] && data.gaRaw[0].data
            ? data.gaRaw[0].data.rowObjects
            : [],
          idsToDisplay,
        );
        setTimeSeriesBarData(timeSeriesBar);

        const demographicsData = data.gaRaw.find(
          (e) => e.custom_name === "query_demographics",
        );
        const demographicsDataFeed = demographicsData || {
          data: { rowObjects: [] },
        };
        const dataDemographicsBar = demographicsBar(
          demographicsDataFeed.data.rowObjects,
        );
        setDataDemographicsBar(dataDemographicsBar);

        const campaignSummary =
          !data.project.isLinkoutCampaign &&
          (!data.project.isDailyDeal ||
            (data.project.isDailyDeal && Array.isArray(rows)))
            ? campaignSummaryData(rows)
            : {};
        const dfpData = {
          impressions: 0,
          clicks: 0,
        };
        if (Array.isArray(rows)) {
          rows.forEach((r) => {
            if (Array.isArray(r.creativesFiltered)) {
              r.creativesFiltered.forEach((c) => {
                dfpData.impressions += c.impressions;
                dfpData.clicks += c.clicks;
              });
            }
          });
        }
        campaignSummary.impressions = dfpData.impressions;
        campaignSummary.clicks = dfpData.clicks;
        setCampaignKPIs(campaignSummary);

        setStoryKPIs(rows);

        // if it's contentHub, generate contentHub overview
        if (data && data.project && data.project.isContentHub) {
          const contentHubOverviewDataRaw = data.gaRaw.find((e) => {
            return e.custom_name === "query_contenthub_overview";
          });

          const contentHubOverviewNested = rollupContentHubOverview(
            contentHubOverviewDataRaw.data.rowObjects,
          );

          const contentHubDetailsNested = rollupContentHubDetails(
            contentHubOverviewDataRaw.data.rowObjects,
          );

          setContentHubDetails(contentHubDetailsNested);
          setContentHubOverview(contentHubOverviewNested);
        }
      }
      if (data.daterange) {
        setDateFrom(data.daterange.from);
        setDateTo(data.daterange.to);
      }

      setReportData(data);
    } catch (err) {
      console.error(err);
      let msg = "Error loading report";
      if (err.response) {
        if (err.response.status === 401) {
          setErrorType("auth");
          store.dispatch(logOut());
          return;
        }
        msg += `: ${err.response.data}`;
      }
      setErrorMessage(msg);
      setIsError(true);
    } finally {
      setIsLoading(false);
    }
  };

  const handleDateChange = (fieldName) => (date) => {
    if (date !== "Invalid Date") {
      if (fieldName === "dateFrom") {
        setDateFrom(date);
      } else if (fieldName === "dateTo") {
        setDateTo(date);
      }
    }
  };

  const favProject = async (id) => {
    try {
      const resp = await axios.post(
        `${process.env.REACT_APP_BACKEND_BASE_URL}/v1/projects/fav/${id}`,
        {},
        {
          withCredentials: true,
          headers: {
            Accept: "application/json",
          },
        },
      );

      store.dispatch(setUserFavorites(resp.data.favorites));
    } catch (err) {
      console.error("Error adding project to favorites", err);
    }
  };

  useEffect(() => {
    getReport(params.id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.id]);

  useEffect(() => {
    if (
      Array.isArray(userFavorites) &&
      userFavorites.includes(projectData._id)
    ) {
      setIsFavorite(true);
    } else {
      setIsFavorite(false);
    }
  }, [userFavorites, projectData]);

  const toggleShowPageviewGraph = () => {
    setShowPageviewGraph(!showPageviewGraph);
  };

  const toggleShowDemographicsGraph = () => {
    setShowDemographicsGraph(!showDemographicsGraph);
  };

  const stories =
    reportData && reportData.CRASS && Array.isArray(reportData.CRASS.stories)
      ? reportData.CRASS.stories
      : storySelection;
  const abTests = reportData && reportData.abTests;
  const gaSummarized = reportData && reportData.gaSummarized;

  if (isError) {
    return <ErrorMessage message={errorMessage} errorType={errorType} />;
  }

  if (isLoading || reportData === null) {
    return (
      <Grid item xs={12} sm={12} md={12}>
        <Box
          sx={{
            marginTop: {
              xs: 0,
              md: "40px",
            },
          }}
        >
          <Loading classes={classes} />
        </Box>
      </Grid>
    );
  }

  return (
    <Grid container spacing={2}>
      {isLoggedIn && (
        <>
          <Fab
            className={classes.fabGoBack}
            color="secondary"
            aria-label="edit"
            component={Link}
            to="/"
          >
            <Box style={{ display: "flex" }}>
              <img
                src="/img/icons/chevron-left.svg"
                alt="Back"
                style={{ width: "28px" }}
              />
            </Box>
          </Fab>
          <Fab
            className={classes.fabEdit}
            color="secondary"
            aria-label="edit"
            onClick={handleOpenDialog}
            elevation={0}
          >
            <Box style={{ display: "flex" }}>
              <img
                src="/img/icons/edit-2.svg"
                alt="Edit"
                style={{ width: "28px" }}
              />
            </Box>
          </Fab>
        </>
      )}

      <>
        <Dialog
          open={dialogOpen}
          closeAfterTransition
          onClose={handleCloseDialog}
          maxWidth="md"
        >
          <div className={classes.modalPaper}>
            <EditProject
              project={reportData.project}
              handleCloseDialog={handleCloseDialog}
              refreshReport={getReport}
            />
          </div>
        </Dialog>

        <Grid item xs={12} sm={12} md={12}>
          <Paper
            elevation={0}
            variant="outlined"
            square
            sx={{
              marginTop: {
                xs: 0,
                md: "40px",
              },
              marginLeft: "-8px",
              marginRight: "8px",
              padding: "16px",
            }}
          >
            <Grid
              container
              spacing={2}
              sx={{
                paddingTop: {
                  xs: "30px",
                  lg: "24px",
                },
                paddingBottom: {
                  xs: "10px",
                  lg: "24px",
                },
                fontFamily: "BatonTurbo-Book",
              }}
            >
              <Grid
                item
                xs={10}
                sm={10}
                md={10}
                className={classes.overviewRow}
              >
                <Typography variant="h6" className={classes.overviewTypography}>
                  {reportData && reportData.CRASS ? (
                    <>
                      <div className={classes.overviewKey}>Order number:</div>
                      <div className={classes.overviewValue}>
                        {reportData.CRASS.order_number}
                      </div>
                    </>
                  ) : (
                    ""
                  )}
                </Typography>
              </Grid>

              {isLoggedIn && (
                <Grid
                  item
                  xs={2}
                  sm={2}
                  md={2}
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    padding: "0px",
                    paddingTop: "0px!important",
                    paddingBottom: "0px!important",
                  }}
                >
                  <Box
                    color={isFavorite ? "secondary" : "default"}
                    aria-label="favorite"
                    onClick={() => favProject(projectData._id)}
                  >
                    {isFavorite ? (
                      <img
                        src="/img/icons/Star--filled.svg"
                        alt="Favorite"
                        style={{ width: "28px", height: "28px" }}
                      />
                    ) : (
                      <img
                        src="/img/icons/Star.svg"
                        alt="Add to favorites"
                        style={{ width: "28px", height: "28px" }}
                      />
                    )}
                  </Box>
                </Grid>
              )}

              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                className={classes.overviewRow}
              >
                <Typography variant="h6" className={classes.overviewTypography}>
                  {reportOverviewData.client ? (
                    <>
                      <div className={classes.overviewKey}>Customer:</div>
                      <div className={classes.overviewValue}>
                        {reportOverviewData.client}
                      </div>
                    </>
                  ) : (
                    ""
                  )}
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                className={classes.overviewRow}
              >
                <Typography variant="h6" className={classes.overviewTypography}>
                  {reportOverviewData.name ? (
                    <>
                      <div className={classes.overviewKey}>Campaign:</div>
                      <div className={classes.overviewValue}>
                        {reportOverviewData.name}
                      </div>
                    </>
                  ) : (
                    ""
                  )}
                </Typography>
              </Grid>

              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                className={classes.overviewRow}
              >
                <Typography variant="h6" className={classes.overviewTypography}>
                  {projectData ? (
                    <>
                      <div className={classes.overviewKey}>Time period:</div>
                      <div className={classes.overviewValue}>
                        {`${moment(dateFrom).format("DD.MM.YY")} - ${moment(
                          dateTo,
                        ).format("DD.MM.YY")}`}
                      </div>
                    </>
                  ) : (
                    ""
                  )}
                </Typography>
              </Grid>
              {campaignKPIs && (
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  className={classes.overviewKpiWrapperDesktop}
                >
                  <Grid container spacing={2}>
                    {/* In case it's contentHub, we need titles */}
                    {reportData &&
                      reportData.project &&
                      reportData.project.isContentHub && (
                        <>
                          <Grid
                            item
                            md={12}
                            className={classes.contentHubOverviewTitle}
                          >
                            Content Hub Data
                          </Grid>

                          {Array.isArray(contentHubOverview) &&
                            contentHubOverview.length > 0 &&
                            contentHubOverview.map((co) => (
                              <Grid item md={12}>
                                <Grid container spacing={2}>
                                  <Grid
                                    item
                                    md={12}
                                    className={
                                      classes.contentHubOverviewSubtitle
                                    }
                                  >
                                    {co.key}
                                  </Grid>
                                  <BigNumber
                                    title={
                                      kpiVocabulary.screenPageViews.description
                                    }
                                    description={
                                      kpiVocabulary.screenPageViews.definition
                                    }
                                    value={
                                      co.value ? co.value.screenPageViews : null
                                    }
                                    color="#3C5F7C"
                                  />
                                  <BigNumber
                                    title={
                                      kpiVocabulary.averageSessionDuration
                                        .description
                                    }
                                    description={
                                      kpiVocabulary.averageSessionDuration
                                        .definition
                                    }
                                    value={
                                      co.value
                                        ? co.value.averageSessionDuration
                                        : null
                                    }
                                    unit="s"
                                    color="#3C5F7C"
                                  />
                                </Grid>
                              </Grid>
                            ))}

                          {Array.isArray(contentHubDetails) &&
                            contentHubDetails.length > 0 && (
                              <Grid
                                item
                                md={12}
                                style={{
                                  marginTop: "16px",
                                  marginBottom: "16px",
                                }}
                              >
                                <Accordion square>
                                  <AccordionSummary
                                    expandIcon={
                                      <img
                                        src="/img/icons/chevron-down.svg"
                                        alt="Expand"
                                        style={{
                                          width: "28px",
                                          height: "28px",
                                        }}
                                      />
                                    }
                                    className={classes.accordionSummaryInner}
                                  >
                                    <div
                                      className={
                                        classes.contentHubOverviewSubtitle
                                      }
                                    >
                                      Detailed Content Hub Data
                                    </div>
                                  </AccordionSummary>
                                  <AccordionDetails
                                    style={{
                                      paddingLeft: "8px",
                                      paddingRight: "24px",
                                    }}
                                  >
                                    <Grid
                                      container
                                      className={classes.gridNoMargin}
                                      spacing={2}
                                    >
                                      <Grid
                                        item
                                        md={12}
                                        className={classes.clickTableRow}
                                      >
                                        <Grid
                                          container
                                          className={classes.clickTableRowInner}
                                          spacing={2}
                                        >
                                          <Grid
                                            item
                                            xs={6}
                                            sm={6}
                                            md={6}
                                            className={
                                              classes.outboundClickUrlContainer
                                            }
                                          >
                                            URL
                                          </Grid>
                                          <Grid
                                            item
                                            xs={3}
                                            sm={3}
                                            md={3}
                                            className={
                                              classes.outboundClickValueContainer
                                            }
                                          >
                                            Pageviews
                                          </Grid>
                                          <Grid
                                            item
                                            xs={3}
                                            sm={3}
                                            md={3}
                                            className={
                                              classes.outboundClickValueContainer
                                            }
                                          >
                                            Avg Time on Page
                                          </Grid>
                                        </Grid>
                                      </Grid>
                                      {contentHubDetails
                                        .sort(
                                          (a, b) =>
                                            b.value.screenPageViews -
                                            a.value.screenPageViews,
                                        )
                                        .map((hd, idhd) => (
                                          <Grid
                                            md={12}
                                            className={classes.clickTableRow}
                                            key={idhd}
                                          >
                                            <Grid
                                              container
                                              className={
                                                classes.clickTableRowInner
                                              }
                                              spacing={2}
                                            >
                                              <Grid
                                                xs={6}
                                                sm={6}
                                                md={6}
                                                className={
                                                  classes.outboundClickUrlContainer
                                                }
                                              >
                                                {hd.key}
                                              </Grid>
                                              <Grid
                                                xs={3}
                                                sm={3}
                                                md={3}
                                                className={
                                                  classes.outboundClickValueContainer
                                                }
                                              >
                                                {hd.value &&
                                                hd.value.screenPageViews
                                                  ? hd.value.screenPageViews
                                                  : "n/a"}
                                              </Grid>
                                              <Grid
                                                xs={3}
                                                sm={3}
                                                md={3}
                                                className={
                                                  classes.outboundClickValueContainer
                                                }
                                              >
                                                {hd.value &&
                                                hd.value.averageSessionDuration
                                                  ? numeral(
                                                      hd.value
                                                        .averageSessionDuration,
                                                    ).format("0.0")
                                                  : "n/a"}
                                              </Grid>
                                            </Grid>
                                          </Grid>
                                        ))}
                                    </Grid>
                                  </AccordionDetails>
                                </Accordion>
                              </Grid>
                            )}
                        </>
                      )}

                    {stories && stories.length > 0 && (
                      <Summary
                        kpis={campaignKPIs}
                        gaSummarized={gaSummarized}
                      />
                    )}
                  </Grid>
                </Grid>
              )}
            </Grid>

            <SocialMediaSummary stories={reportData.socialMediaStories} />

            <LongformSummary
              longforms={reportData.longforms.longForms}
              aggregationTotal={reportData.longforms.aggregationTotal}
            />

            <Grid
              container
              spacing={2}
              sx={{
                paddingTop: {
                  xs: "30px",
                  lg: "24px",
                },
                paddingBottom: {
                  xs: "10px",
                  lg: "24px",
                },
                fontFamily: "BatonTurbo-Book",
              }}
            >
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                className={classes.overviewRow}
              >
                <Typography
                  variant="h6"
                  className={classes.overviewTypography}
                  style={{ paddingBottom: "16px" }}
                >
                  {projectData ? (
                    <>
                      <div className={classes.overviewKey}>
                        Change time period:
                      </div>
                      <div className={classes.overviewValue}>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "flex-end",
                          }}
                        >
                          <DatePicker
                            className={classes.dateControl}
                            id="date-picker-dialog-from"
                            format="dd.MM.yy"
                            value={dateFrom || ""}
                            onChange={handleDateChange("dateFrom")}
                            InputProps={{
                              style: {
                                maxWidth: "240px",
                                boxShadow: "none",
                                fontStyle: "bold",
                                fontWeight: "700",
                                fontSize: "18px",
                                paddingTop: "0px",
                                height: "24px",
                              },
                            }}
                            components={{
                              OpenPickerIcon: () => (
                                <img
                                  src="/img/icons/calendar-grey.svg"
                                  alt="Pick from date"
                                  style={{ width: "28px", height: "28px" }}
                                />
                              ),
                            }}
                            InputAdornmentProps={{
                              position: "start",
                              className: classes.calendarIconAdornment,
                            }}
                            variant="dialog"
                            renderInput={(params) => <TextField {...params} />}
                          />
                          <DatePicker
                            style={{ boxShadow: "none" }}
                            className={classes.dateControl}
                            id="date-picker-dialog-to"
                            format="dd.MM.yy"
                            value={dateTo || ""}
                            onChange={handleDateChange("dateTo")}
                            InputProps={{
                              style: {
                                boxShadow: "none",
                                maxWidth: "240px",
                                fontStyle: "bold",
                                fontWeight: "700",
                                fontSize: "18px",
                                paddingTop: "0px",
                                height: "24px",
                              },
                            }}
                            InputAdornmentProps={{
                              position: "start",
                              className: classes.calendarIconAdornment,
                            }}
                            components={{
                              OpenPickerIcon: () => (
                                <img
                                  src="/img/icons/calendar-grey.svg"
                                  alt="Pick from date"
                                  style={{ width: "28px", height: "28px" }}
                                />
                              ),
                            }}
                            variant="dialog"
                            renderInput={(params) => <TextField {...params} />}
                          />
                          <Box
                            style={{
                              display: "inline-block",
                              alignItems: "center",
                              transform: "translate(0, 4px)",
                            }}
                          >
                            <Button
                              variant="outlined"
                              onClick={() => {
                                getReport(params.id);
                              }}
                              style={{
                                transform: "translate(0px, 6px)",
                                textTransform: "none",
                                borderRadius: "3px",
                                boxShadow: "0 1px 1px 0 rgba(0, 0, 0, 0.09)",
                                border: "solid 2px #223647",
                                color: "#223647",
                                width: "112px",
                              }}
                            >
                              Update
                            </Button>
                          </Box>
                        </div>
                      </div>
                    </>
                  ) : (
                    ""
                  )}
                </Typography>
              </Grid>
            </Grid>
          </Paper>
        </Grid>

        {campaignKPIs && (
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            className={classes.overviewKpiWrapperMobile}
          >
            <Grid container spacing={2}>
              {reportData &&
                reportData.project &&
                reportData.project.isContentHub && (
                  <>
                    <Grid
                      item
                      md={12}
                      sm={12}
                      xs={12}
                      className={classes.contentHubOverviewTitle}
                    >
                      Content Hub Data
                    </Grid>
                    {Array.isArray(contentHubOverview) &&
                      contentHubOverview.length > 0 &&
                      contentHubOverview.map((co) => (
                        <Grid item md={12}>
                          <Grid container spacing={2}>
                            <Grid
                              item
                              md={12}
                              sm={12}
                              xs={12}
                              className={classes.contentHubOverviewSubtitle}
                            >
                              {co.key}
                            </Grid>
                            <BigNumber
                              title={kpiVocabulary.screenPageViews.description}
                              description={
                                kpiVocabulary.screenPageViews.definition
                              }
                              value={co.value ? co.value.screenPageViews : null}
                              color="#3C5F7C"
                            />
                            <BigNumber
                              title={
                                kpiVocabulary.averageSessionDuration.description
                              }
                              description={
                                kpiVocabulary.averageSessionDuration.definition
                              }
                              value={
                                co.value
                                  ? co.value.averageSessionDuration
                                  : null
                              }
                              unit="s"
                              color="#3C5F7C"
                            />
                          </Grid>
                        </Grid>
                      ))}

                    {Array.isArray(contentHubDetails) &&
                      contentHubDetails.length > 0 && (
                        <Grid
                          item
                          md={12}
                          style={{
                            marginTop: "16px",
                            marginBottom: "16px",
                          }}
                        >
                          <Accordion square>
                            <AccordionSummary
                              expandIcon={
                                <img
                                  src="/img/icons/chevron-down.svg"
                                  alt="Expand"
                                  style={{
                                    width: "28px",
                                    height: "28px",
                                  }}
                                />
                              }
                              className={classes.accordionSummaryInner}
                            >
                              <div
                                className={classes.contentHubOverviewSubtitle}
                              >
                                Detailed Content Hub Data
                              </div>
                            </AccordionSummary>
                            <AccordionDetails>
                              <Grid
                                container
                                className={classes.gridNoMargin}
                                spacing={2}
                              >
                                <Grid
                                  item
                                  md={12}
                                  sm={12}
                                  xs={12}
                                  className={classes.clickTableRow}
                                >
                                  <Grid
                                    container
                                    className={classes.clickTableRowInner}
                                    spacing={2}
                                  >
                                    <Grid
                                      item
                                      xs={6}
                                      sm={6}
                                      md={6}
                                      className={
                                        classes.outboundClickUrlContainer
                                      }
                                      style={{
                                        alignItems: "flex-end",
                                        alignSelf: "flex-end",
                                      }}
                                    >
                                      URL
                                    </Grid>
                                    <Grid
                                      item
                                      xs={3}
                                      sm={3}
                                      md={3}
                                      className={
                                        classes.outboundClickValueContainer
                                      }
                                      style={{ alignItems: "flex-end" }}
                                    >
                                      Pageviews
                                    </Grid>
                                    <Grid
                                      item
                                      xs={3}
                                      sm={3}
                                      md={3}
                                      className={
                                        classes.outboundClickValueContainer
                                      }
                                      style={{ alignItems: "flex-end" }}
                                    >
                                      Avg Time on Page
                                    </Grid>
                                  </Grid>
                                </Grid>
                                {contentHubDetails.map((hd, idhd) => (
                                  <Grid
                                    item
                                    md={12}
                                    sm={12}
                                    xs={12}
                                    className={classes.clickTableRow}
                                    key={idhd}
                                  >
                                    <Grid
                                      container
                                      className={classes.clickTableRowInner}
                                      spacing={2}
                                    >
                                      <Grid
                                        item
                                        xs={6}
                                        sm={6}
                                        md={6}
                                        className={
                                          classes.outboundClickUrlContainer
                                        }
                                      >
                                        {hd.key}
                                      </Grid>
                                      <Grid
                                        item
                                        xs={3}
                                        sm={3}
                                        md={3}
                                        className={
                                          classes.outboundClickValueContainer
                                        }
                                      >
                                        {hd.value && hd.value.screenPageViews
                                          ? hd.value.screenPageViews
                                          : "n/a"}
                                      </Grid>
                                      <Grid
                                        item
                                        xs={3}
                                        sm={3}
                                        md={3}
                                        className={
                                          classes.outboundClickValueContainer
                                        }
                                      >
                                        {hd.value &&
                                        hd.value.averageSessionDuration
                                          ? numeral(
                                              hd.value.averageSessionDuration,
                                            ).format("0.0")
                                          : "n/a"}
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                ))}
                              </Grid>
                            </AccordionDetails>
                          </Accordion>
                        </Grid>
                      )}
                    <Grid
                      item
                      md={12}
                      sm={12}
                      xs={12}
                      className={classes.contentHubOverviewTitle}
                    >
                      Teasers and Stories
                    </Grid>
                  </>
                )}
              <BigNumber
                title={kpiVocabulary.screenPageViews.description}
                description={kpiVocabulary.screenPageViews.definition}
                value={campaignKPIs ? campaignKPIs.screenPageViews : null}
              />
              <BigNumber
                title={kpiVocabulary.averageSessionDuration.description}
                description={kpiVocabulary.averageSessionDuration.definition}
                value={
                  campaignKPIs ? campaignKPIs.averageSessionDuration : null
                }
                unit="s"
              />
              <BigNumber
                title={kpiVocabulary.ctaClicks.description}
                description={kpiVocabulary.ctaClicks.definition}
                value={
                  campaignKPIs && campaignKPIs.events
                    ? campaignKPIs.events.ctaClicks
                    : null
                }
              />
              <BigNumber
                title={kpiVocabulary.videoPlays.description}
                description={kpiVocabulary.videoPlays.definition}
                value={
                  campaignKPIs && campaignKPIs.events
                    ? campaignKPIs.events.videoPlays
                    : null
                }
              />
              <BigNumber
                title={kpiVocabulary.outboundClicks.description}
                description={kpiVocabulary.outboundClicks.definition}
                value={
                  campaignKPIs && campaignKPIs.events
                    ? campaignKPIs.events.outboundClicks
                    : null
                }
              />
              <BigNumber
                title={kpiVocabulary.shares.description}
                description={kpiVocabulary.shares.definition}
                value={
                  campaignKPIs && campaignKPIs.events
                    ? campaignKPIs.events.shares
                    : null
                }
              />
              <BigNumber
                title={kpiVocabulary.viewImpressions.description}
                description={kpiVocabulary.viewImpressions.definition}
                value={
                  campaignKPIs && campaignKPIs.DFP
                    ? campaignKPIs.DFP.impressions
                    : null
                }
              />
              <BigNumber
                title={kpiVocabulary.clicks.description}
                description={kpiVocabulary.clicks.definition}
                value={
                  campaignKPIs && campaignKPIs.DFP
                    ? campaignKPIs.DFP.clicks
                    : null
                }
              />
              <BigNumber
                title={kpiVocabulary.ctr.description}
                description={kpiVocabulary.ctr.definition}
                value={
                  campaignKPIs && campaignKPIs.DFP
                    ? (campaignKPIs.DFP.clicks / campaignKPIs.DFP.impressions) *
                      100
                    : null
                }
                unit="%"
                decimals={3}
              />
            </Grid>
          </Grid>
        )}

        {Array.isArray(timeSeriesBarData.data) &&
          timeSeriesBarData.data.length > 0 && (
            <Grid item xs={12} sm={12} md={12}>
              <Paper
                className={classes.paperGraph}
                elevation={0}
                variant="outlined"
              >
                <Grid container spacing={2}>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    className={classes.graphContainer}
                  >
                    <Grid container spacing={2}>
                      <Grid
                        item
                        xs={9}
                        sm={12}
                        md={12}
                        className={classes.graphTitleContainer}
                      >
                        <Typography variant="h6" className={classes.graphTitle}>
                          Pageviews over time
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xs={3}
                        sm={false}
                        md={false}
                        className={classes.graphToggleButtonContainer}
                      >
                        <IconButton
                          onClick={toggleShowPageviewGraph}
                          size="large"
                        >
                          {showPageviewGraph ? (
                            <img
                              src="/img/icons/chevron-down.svg"
                              alt="Hide pageview graph"
                              style={{
                                width: "28px",
                                height: "28px",
                                transform: "rotate(180deg)",
                              }}
                            />
                          ) : (
                            <img
                              src="/img/icons/chevron-down.svg"
                              alt="Show pageview graph"
                              style={{ width: "28px", height: "28px" }}
                            />
                          )}
                        </IconButton>
                      </Grid>
                    </Grid>
                    {showPageviewGraph ? (
                      <BarStackedTS data={timeSeriesBarData} />
                    ) : null}
                  </Grid>
                </Grid>
                {false && // TODO: Bring back with DFP data instead of GA
                  dataDemographicsBar.data.length > 0 &&
                  dataDemographicsBar.ageBrackets.length > 0 && (
                    <Grid
                      container
                      spacing={2}
                      style={{ marginBottom: "3rem" }}
                    >
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={12}
                        className={classes.graphContainer}
                      >
                        <Grid container spacing={2}>
                          <Grid
                            item
                            xs={9}
                            sm={12}
                            md={12}
                            className={classes.graphTitleContainer}
                          >
                            <Typography
                              variant="h6"
                              className={classes.graphTitle}
                            >
                              Demography
                            </Typography>
                          </Grid>
                          <Grid
                            item
                            xs={3}
                            sm={false}
                            md={false}
                            className={classes.graphToggleButtonContainer}
                          >
                            <IconButton
                              onClick={toggleShowDemographicsGraph}
                              size="large"
                            >
                              {showDemographicsGraph ? (
                                <img
                                  src="/img/icons/chevron-down.svg"
                                  alt="Hide demographics graph"
                                  style={{
                                    width: "28px",
                                    height: "28px",
                                    transform: "rotate(180deg)",
                                  }}
                                />
                              ) : (
                                <img
                                  src="/img/icons/chevron-down.svg"
                                  alt="Show demographics graph"
                                  style={{ width: "28px", height: "28px" }}
                                />
                              )}
                            </IconButton>
                          </Grid>
                        </Grid>

                        {showDemographicsGraph && (
                          <BarDemographics data={dataDemographicsBar} />
                        )}
                      </Grid>
                    </Grid>
                  )}
              </Paper>
            </Grid>
          )}

        <Grid item xs={12} sm={12} md={12}>
          {stories && stories.length > 0 && (
            <Grid container spacing={2} className={classes.storiesContainer}>
              <Typography
                variant="h6"
                gutterBottom
                style={{ marginTop: "2em" }}
              >
                Teasers and Stories
              </Typography>
              <Grid item xs={12} sm={12} md={12}>
                <Stories
                  className={classes.centeredTabs}
                  classes={classes}
                  stories={stories}
                  kpis={storyKPIs}
                  projectData={projectData}
                  abTests={abTests}
                  gaSummarized={gaSummarized}
                />
              </Grid>
            </Grid>
          )}

          {reportData.longforms.longForms.length > 0 && (
            <Grid item xs={12} sm={12} md={12}>
              <Typography
                variant="h6"
                gutterBottom
                style={{ marginTop: "2em", marginBottom: "1em" }}
              >
                Longforms
              </Typography>
              <Grid container spacing={2} className={classes.storiesContainer}>
                {reportData.longforms.longForms.map((l) => (
                  <div
                    key={l.url}
                    style={{
                      width: "100%",
                      marginTop: "8px",
                      marginBottom: "8px",
                    }}
                  >
                    <Longform longform={l} classes={classes} />
                  </div>
                ))}
              </Grid>
            </Grid>
          )}

          {reportData.socialMediaStories.length > 0 && (
            <Grid container spacing={2} className={classes.storiesContainer}>
              <Grid item xs={12} sm={12} md={12}>
                <SocialMediaStories
                  className={classes.centeredTabs}
                  classes={classes}
                  stories={reportData.socialMediaStories}
                  abTests={reportData.abTests}
                />
              </Grid>
            </Grid>
          )}
        </Grid>

        {isAdmin && (
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            style={{ paddingLeft: "8px", paddingRight: "8px" }}
          >
            <Accordion>
              <AccordionSummary>
                <Typography variant="h6">Data Raw</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <DataExplorer data={reportData} />
              </AccordionDetails>
            </Accordion>
          </Grid>
        )}
      </>
    </Grid>
  );
};

export default Report;
