import { useEffect, useState } from "react";
import { Box, FormControl, InputLabel, MenuItem, Select } from "@mui/material";

function TargetReachDropdown({ label, options, selection, onChange, index }) {
  const [target, setTarget] = useState(selection);

  useEffect(() => {
    setTarget(selection);
  }, [selection]);

  const renderedMenuItems = options.map((option) => {
    return (
      <MenuItem key={option.id} value={option.id}>
        {option.name}
      </MenuItem>
    );
  });

  const handleChange = (event) => {
    const value = event.target.value;
    setTarget(value);
    onChange(index)(event.target.value);
  };

  return (
    <Box sx={{ minWidth: 120 }}>
      <FormControl fullWidth>
        <InputLabel>Target Reach</InputLabel>
        <Select value={target} label={label} onChange={handleChange}>
          <MenuItem key="" value="">
            <em>None</em>
          </MenuItem>
          {renderedMenuItems}
        </Select>
      </FormControl>
    </Box>
  );
}

export default TargetReachDropdown;
