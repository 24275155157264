import { useSelector } from "react-redux";
import {
  Grid,
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from "@mui/material";
import moment from "moment";
import numeral from "numeral";
import VideoMetrics from "./VideoMetrics";
import LinearProgress from "@mui/material/LinearProgress";

const Story = ({ story, classes }) => {
  const isLoggedIn = useSelector((state) => state.isLoggedIn);

  function LinearProgressWithLabel(props) {
    let realValue = props.value;
    let value = props.value > 100 ? 100 : props.value;
    let newProps = { ...props, value };

    return (
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Box sx={{ width: "100%", mr: 1 }}>
          <LinearProgress variant="determinate" {...newProps} />
        </Box>
        <Box sx={{ minWidth: 35 }}>
          <Typography
            variant="body2"
            color="text.secondary"
          >{`${realValue}%`}</Typography>
        </Box>
      </Box>
    );
  }

  return (
    <Accordion
      elevation={0}
      variant="outlined"
      square
      classes={{
        root: classes.storyPanelUnder,
        expanded: classes.storyPanelExpanded,
      }}
    >
      <AccordionSummary
        style={{ margin: 0, width: "100%" }}
        expandIcon={
          <img
            src="/img/icons/chevron-down.svg"
            alt="Expand"
            style={{ width: "28px", height: "28px" }}
          />
        }
        aria-controls="panel1a-content"
      >
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={12}>
            <Typography
              variant="h6"
              sx={{
                fontSize: {
                  xs: "18px",
                  md: "22px",
                },
                color: "#223647",
              }}
            >
              {isLoggedIn ? `ID ${story.id}: ` : ""}
              {story.message &&
                (story.message.length > 100
                  ? `${story.message.substring(0, 99)}...`
                  : story.message)}
            </Typography>
          </Grid>
        </Grid>
      </AccordionSummary>

      <AccordionDetails
        style={{
          margin: 0,
          width: "100%",
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={12}>
            <Grid container spacing={2}>
              <Grid item xs={3} sm={3} md={3}>
                {story.pictureURL ? (
                  <Box
                    className={classes.imgBoxSquare}
                    style={{
                      backgroundImage: `url(${story.pictureURL})`,
                    }}
                  />
                ) : null}
              </Grid>
              <Grid item xs={9} sm={9} md={9}>
                <Typography variant="h6" className={classes.dfpMetricsValue}>
                  {moment(story.created).format("DD.MM.YYYY")}
                </Typography>
                <Typography
                  variant="h6"
                  className={classes.dfpMetricsValueBold}
                >
                  {story.network.charAt(0).toUpperCase() +
                    story.network.slice(1)}{" "}
                  / {story.parentName} / {story.postType}
                </Typography>
                <br />
                <Typography
                  variant="h6"
                  className={classes.dfpMetricsKeyBold}
                  style={{ marginTop: "1em" }}
                >
                  Link:{" "}
                </Typography>
                <Typography
                  variant="h6"
                  className={classes.dfpMetricsValue}
                  style={{ marginTop: "1em", marginBottom: "1.5em" }}
                >
                  <a href={story.link} target="_blank" rel="noreferrer">
                    {story.link}
                  </a>
                </Typography>
                <br />
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12} md={12}>
                    <Typography variant="h6" className={classes.ogTitle}>
                      {story.message}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    className={classes.dfpMetricsWrapper}
                  >
                    {Boolean(story.impressions) && (
                      <Typography
                        variant="h6"
                        className={classes.dfpMetricsValueBold}
                      >
                        Impressions: {numeral(story.impressions).format()}
                      </Typography>
                    )}
                    {Boolean(story.reach) && (
                      <Typography
                        variant="h6"
                        className={classes.dfpMetricsValueBold}
                      >
                        Reach: {numeral(story.reach).format()}
                      </Typography>
                    )}
                    {Boolean(story.interactions) && Boolean(story.reach) && (
                      <Typography
                        variant="h6"
                        className={classes.dfpMetricsValueBold}
                      >
                        Interaction Rate:{" "}
                        {numeral(
                          (story.interactions / story.reach) * 100,
                        ).format("0.000")}
                        %
                      </Typography>
                    )}
                    {Boolean(story.reactions) && (
                      <Typography
                        variant="h6"
                        className={classes.dfpMetricsValueBold}
                      >
                        Reactions: {numeral(story.reactions).format()}
                      </Typography>
                    )}
                    {Boolean(story.comments) && (
                      <Typography
                        variant="h6"
                        className={classes.dfpMetricsValue}
                      >
                        Comments: {numeral(story.comments).format()}
                      </Typography>
                    )}
                    {Boolean(story.shares) && (
                      <Typography
                        variant="h6"
                        className={classes.dfpMetricsValue}
                      >
                        Shares: {story.shares}
                      </Typography>
                    )}
                    {Boolean(story.saved) && (
                      <Typography
                        variant="h6"
                        className={classes.dfpMetricsValue}
                      >
                        Saved: {numeral(story.saved).format()}
                      </Typography>
                    )}
                    {Boolean(story.postClicks) && (
                      <Typography
                        variant="h6"
                        className={classes.dfpMetricsValue}
                      >
                        Post Clicks: {story.postClicks}
                      </Typography>
                    )}
                    {Boolean(story.outboundClicks) && (
                      <Typography
                        variant="h6"
                        className={classes.dfpMetricsValue}
                      >
                        Outbound Clicks: {story.outboundClicks}
                      </Typography>
                    )}
                    {Boolean(story.outboundClicks || story.postClicks) &&
                      Boolean(story.impressions) && (
                        <Typography
                          variant="h6"
                          className={classes.dfpMetricsValue}
                        >
                          CTR:{" "}
                          {numeral(
                            (story.outboundClicks || story.postClicks) /
                              story.impressions,
                          ).format("0.000%")}
                        </Typography>
                      )}
                  </Grid>

                  {Boolean(
                    story.targetReach && story.targetReach.targetReach,
                  ) && (
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      className={classes.dfpMetricsWrapper}
                    >
                      <Typography
                        variant="h6"
                        className={classes.dfpMetricsValue}
                      >
                        Target: {story.targetReach.name}
                      </Typography>
                      <Box sx={{ width: "100%" }}>
                        <LinearProgressWithLabel
                          value={
                            Math.round(
                              (story.targetReach.currentReach /
                                story.targetReach.targetReach) *
                                10000,
                            ) / 100
                          }
                        />
                      </Box>
                      <Typography
                        variant="h6"
                        className={classes.dfpMetricsValue}
                      >
                        {numeral(story.targetReach.currentReach).format()} /
                        {numeral(story.targetReach.targetReach).format()}
                      </Typography>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Grid>

            {Boolean(story.video) && <VideoMetrics metrics={story.video} />}
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};

export default Story;
