import { Grid } from "@mui/material";
import BigNumber from "../BigNumber";

const color = "#376A93";

const LongformSummary = ({ longforms, aggregationTotal }) => {
  if (!longforms || longforms.length === 0) {
    return null;
  }

  let screenPageViews = 0;
  let sessions = 0;
  let totalSessionDuration = 0;
  let outboundClicks = 0;
  let totalScrollDepth = 0;
  longforms.forEach((lf) => {
    screenPageViews += lf.screenPageViews;
    sessions += lf.sessions;
    totalSessionDuration += lf.sessions * lf.averageSessionDuration;
    if (lf.outboundClicks) {
      outboundClicks += lf.outboundClicks;
    }
    if (lf.scrollDepth) {
      totalScrollDepth += lf.sessions * lf.scrollDepth;
    }
  });
  const averageSessionDuration =
    aggregationTotal.avgTimeOnPage > 0
      ? aggregationTotal.avgTimeOnPage
      : totalSessionDuration / sessions;
  const averageScrollDepth =
    aggregationTotal.scrollDepth > 0
      ? aggregationTotal.scrollDepth
      : totalScrollDepth / sessions;

  return (
    <Grid
      container
      spacing={2}
      sx={{
        paddingTop: 0,
        paddingBottom: {
          xs: "10px",
          lg: "24px",
        },
        fontFamily: "BatonTurbo-Book",
      }}
    >
      <Grid item xs={12} sm={12} md={12}>
        <Grid container spacing={2}>
          <Grid
            item
            md={12}
            sm={12}
            xs={12}
            sx={{
              fontSize: {
                xs: "14px",
                md: "18px",
              },
              color: "#262627",
              lineHeight: "1.11",
              marginBottom: "6px",
              marginTop: "2em",
            }}
          >
            Longforms
          </Grid>
          <BigNumber
            title="Pageviews"
            description="Amount of displays of longforms"
            value={screenPageViews}
            color={color}
          />
          <BigNumber
            title="Avg Time on Page"
            description="Average session duration of users"
            value={averageSessionDuration}
            unit="s"
            color={color}
          />
          <BigNumber
            title="Outbound Clicks"
            description="Amount of outbound clicks on hyperlinks, pictures and logos (excl. CTA clicks)"
            value={outboundClicks}
            color={color}
          />
          <BigNumber
            title="Avg Scroll Depth"
            description="Average scroll depth"
            value={averageScrollDepth}
            unit="%"
            color={color}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default LongformSummary;
