import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Grid,
  Box,
  Table,
  TableCell,
  TableRow,
  TableBody,
  Typography,
} from "@mui/material";
import moment from "moment";
import numeral from "numeral";
import { ResponsiveLine } from "@nivo/line";

const VideoMetrics = ({ metrics }) => {
  const chartData = [
    {
      id: "video-retention",
      data: Object.entries(metrics.retentionGraph)
        .map(([k, v]) => ({
          x: k * metrics.length,
          y: v,
        }))
        .sort((a, b) => a.x - b.x),
    },
  ];

  return (
    <Accordion
      elevation={0}
      square
      style={{
        borderTop: "2px solid #f7f7fa",
        boxShadow: "none",
        width: "100%",
        marginTop: "30px",
      }}
    >
      <AccordionSummary
        expandIcon={
          <img
            src="/img/icons/chevron-down.svg"
            alt="Expand"
            style={{ width: "28px", height: "28px" }}
          />
        }
      >
        <Grid item xs={12} sm={12} md={12}>
          <Typography variant="h6" sx={{ color: "#376a93" }}>
            Video Metrics (Paid Only)
          </Typography>
        </Grid>
      </AccordionSummary>
      <AccordionDetails>
        <Table size="small">
          <TableBody>
            <TableRow>
              <TableCell component="th" scope="row">
                Length
              </TableCell>
              <TableCell align="right">
                {moment.utc(metrics.length).format("HH:mm:ss")}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                Average Time Watched
              </TableCell>
              <TableCell align="right">
                {moment.utc(metrics.avgTimeWatched).format("HH:mm:ss")}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                Plays
              </TableCell>
              <TableCell align="right">
                {numeral(metrics.plays).format()}
              </TableCell>
            </TableRow>
            {metrics.thruPlays !== 0 ? (
              <TableRow>
                <TableCell component="th" scope="row">
                  ThruPlays (15 sec view)
                </TableCell>
                <TableCell align="right">
                  {numeral(metrics.thruPlays).format()}
                </TableCell>
              </TableRow>
            ) : null}
            {metrics.thruPlays6s !== 0 ? (
              <TableRow>
                <TableCell component="th" scope="row">
                  ThruPlays (6 sec view)
                </TableCell>
                <TableCell align="right">
                  {numeral(metrics.thruPlays6s).format()}
                </TableCell>
              </TableRow>
            ) : null}
          </TableBody>
        </Table>

        <Typography style={{ marginTop: "2em" }}>Video Retention</Typography>
        <Box
          sx={{
            height: {
              xs: "180px",
              sm: "200px",
              lg: "220px",
            },
          }}
        >
          <ResponsiveLine
            data={chartData}
            margin={{ top: 15, right: 25, bottom: 50, left: 40 }}
            enablePoints={false}
            colors={["#5587b0"]}
            enableGridX={false}
            axisLeft={{ format: (v) => `${v * 100}%` }}
            axisBottom={{
              tickValues: [0, 0.25, 0.5, 0.75, 1].map(
                (p) => p * metrics.length,
              ),
              format: (v) => moment.utc(v).format("HH:mm:ss"),
            }}
          />
        </Box>
      </AccordionDetails>
    </Accordion>
  );
};

export default VideoMetrics;
