import { useEffect, useState } from "react";
import { Box, IconButton, InputAdornment, OutlinedInput } from "@mui/material";

function URLField({ url, removeURL, index, editURL }) {
  const [fieldValue, setFieldValue] = useState(url);

  useEffect(() => {
    setFieldValue(url);
  }, [url]);

  const handleURLChange = (e) => {
    const value = e.target.value;
    setFieldValue(value);
    editURL(index)(value);
  };

  return (
    <OutlinedInput
      key={index}
      id={`url-field-${index}`}
      autoFocus
      value={fieldValue}
      onChange={handleURLChange}
      endAdornment={
        <InputAdornment position="end">
          <IconButton
            aria-label="Remove URL"
            onClick={removeURL(index)}
            edge="end"
            size="large"
          >
            <Box style={{ display: "flex" }}>
              <img
                src="/img/icons/minus-circle.svg"
                alt="Remove URL"
                style={{ width: "23.6px", height: "23.6px" }}
              />
            </Box>
          </IconButton>
        </InputAdornment>
      }
    />
  );
}

export default URLField;
