import { rollups, sum } from "d3-array";
import moment from "moment";
import drillEvents from "../utils/drillEvents";
import calculateCTAClicks from "../utils/ctaClick";

export const nestByStoryID = (data) => {
  return rollups(
    data,
    (leaves) => ({
      screenPageViews: sum(leaves, (d) => d.screenPageViews),
      sessions: sum(leaves, (d) => d.sessions),
      averageSessionDuration:
        sum(
          leaves,
          (d) =>
            (d.averageSessionDuration /
              (Number(d.screenPageViewsPerSession) || 1)) *
            d.sessions,
        ) / sum(leaves, (d) => d.sessions),
      byMediaTitle: nestByMediaTitle(leaves),
    }),
    (d) => d.storyID,
  ).map(([key, value]) => ({ key, value }));
};

export const campaignSummaryData = (stories) =>
  rollups(stories, (leaves) => {
    const outboundClicks = leaves.reduce((ps, s) => {
      const storyOutboundClicksOutboundLink = s.events
        .filter(
          (e) =>
            (e.eventName === "click" && Boolean(e.outbound)) ||
            e.eventName.startsWith("outboundlink_"),
        )
        .reduce((p, e) => p + parseInt(e.eventCount), 0);
      const outboundClicksWithoutCTA = s.events
        .filter(
          (e) =>
            e.eventName === "outbound_click" && !e.element_type.includes("CTA"),
        )
        .reduce((p, e) => p + parseInt(e.eventCount), 0);

      return ps + storyOutboundClicksOutboundLink + outboundClicksWithoutCTA;
    }, 0);

    const ctaClicks = leaves.reduce((ps, s) => {
      return ps + calculateCTAClicks(s.events);
    }, 0);

    return {
      screenPageViews: sum(leaves, (d) => d.value.screenPageViews),
      sessions: sum(leaves, (d) => d.value.sessions),
      averageSessionDuration:
        sum(
          leaves,
          (d) =>
            (d.value.averageSessionDuration /
              (Number(d.screenPageViewsPerSession) || 1)) *
            d.value.sessions,
        ) / sum(leaves, (d) => d.value.sessions),
      videoPlays:
        sum(leaves, (d) => drillEvents(d.events, "video_play")) +
        sum(leaves, (d) => drillEvents(d.events, "video_start")),
      outboundClicks,
      ctaClicks,
      shares:
        sum(leaves, (d) => drillEvents(d.events, "share_start")) +
        sum(leaves, (d) => drillEvents(d.events, "open_share_options")) +
        sum(leaves, (d) => drillEvents(d.events, "begin_to_share")) +
        sum(leaves, (d) => drillEvents(d.events, "share")),
      formSubmissions: sum(leaves, (d) => drillEvents(d.events, "form_submit")),
    };
  });

export const rollupTimeSeriesBar = (data, idsToInclude) => {
  let finalData = [];
  const storyIdsSet = new Set();
  const dateSet = new Set();

  let dataToUse;

  if (Array.isArray(data) && Array.isArray(idsToInclude)) {
    dataToUse = data.filter((e) => idsToInclude.includes(e.storyID));
  } else {
    dataToUse = data;
  }

  dataToUse.forEach((e) => {
    // create set of unique story IDs
    if (e.storyID) {
      storyIdsSet.add(e.storyID);
    }

    // create set of unique dates for axis ticks
    if (e.date) {
      const dateString = moment(e.date).format("YYYY-MM-DD");
      dateSet.add(dateString);
    }
  });

  const nested = rollups(
    dataToUse,
    (leaves) => ({
      screenPageViews: sum(leaves, (d) => d.screenPageViews),
    }),
    (d) => moment(d.date).format("YYYY-MM-DD"),
    (d) => d.storyID,
  ).map(([key, values]) => ({
    key,
    values: values.map(([key, value]) => ({ key, value })),
  }));

  finalData = nested.map((e) => {
    const { key, values } = e;
    const results = {
      date: key,
    };

    values.forEach((v) => {
      results[v.key] = v.value.screenPageViews;
    });

    return results;
  });

  return {
    dates: Array.from(dateSet),
    storyIds: Array.from(storyIdsSet),
    data: finalData,
  };
};

export const demographicsBar = (data) => {
  const ageBracketSet = new Set();
  const finalData = [];

  const dataSorted = data.sort((a, b) =>
    a.userAgeBracket.localeCompare(b.userAgeBracket),
  );

  const nested = rollups(
    dataSorted,
    (leaves) => ({
      male: sum(leaves, (d) => {
        if (d.userGender === "male") {
          return d.totalUsers;
        }
        return 0;
      }),
      female: sum(leaves, (d) => {
        if (d.userGender === "female") {
          return d.totalUsers;
        }
        return 0;
      }),
    }),
    (d) => d.userAgeBracket,
  ).map(([key, value]) => ({ key, value }));

  let totalPageviews = 0;
  nested.forEach((e) => {
    const pvFemale = e.value.female ? e.value.female : 0;
    const pvMale = e.value.male ? e.value.male : 0;
    totalPageviews = totalPageviews + pvMale + pvFemale;
  });

  nested.forEach((e) => {
    ageBracketSet.add(e.key);

    const cleaned = {
      userAgeBracket: e.key,
      female: Number(e.value.female) / Number(totalPageviews),
      male: -Number(e.value.male) / Number(totalPageviews),
    };

    finalData.push(cleaned);
  });

  return {
    ageBrackets: Array.from(ageBracketSet),
    data: finalData,
  };
};

export const rollupContentHubOverview = (data) =>
  rollups(
    data,
    (leaves) => ({
      screenPageViews: sum(leaves, (d) => d.screenPageViews),
      sessions: sum(leaves, (d) => d.sessions),
      averageSessionDuration:
        sum(
          leaves,
          (d) =>
            (d.averageSessionDuration /
              (Number(d.screenPageViewsPerSession) || 1)) *
            d.sessions,
        ) / sum(leaves, (d) => d.sessions),
    }),
    (d) => {
      // shorten key by removing host
      const keyOriginal = d.pageHost;
      let keyShort = keyOriginal
        .replace("hub.commercial-publishing.ch/", "")
        .replace("hub.tx.group/", "");

      const languagePattern = /^(de|fr|it)\/.*/i;

      const langMatch = keyShort.match(languagePattern);

      let langTitleString;

      const languageMap = { de: "German", fr: "French", it: "Italian" };

      if (
        Array.isArray(langMatch) &&
        langMatch[1] &&
        languageMap[langMatch[1]]
      ) {
        langTitleString = `Content Hub Overview (${languageMap[langMatch[1]]})`;
      } else {
        langTitleString = `Content Hub Overview ${keyShort}`;
      }
      return langTitleString;
    },
  ).map(([key, value]) => ({ key, value }));

export const rollupContentHubDetails = (data) =>
  rollups(
    data,
    (leaves) => ({
      screenPageViews: sum(leaves, (d) => d.screenPageViews),
      sessions: sum(leaves, (d) => d.sessions),
      averageSessionDuration:
        sum(
          leaves,
          (d) =>
            (d.averageSessionDuration /
              (Number(d.screenPageViewsPerSession) || 1)) *
            d.sessions,
        ) / sum(leaves, (d) => d.sessions),
    }),
    (d) => {
      // shorten key by removing host
      const keyOriginal = d.pageHost;
      const keyShort = keyOriginal.replace("hub.commercial-publishing.ch/", "");
      return keyShort;
    },
  ).map(([key, value]) => ({ key, value }));

const nestByMediaTitle = (data) =>
  rollups(
    data,
    (leaves) => ({
      screenPageViews: sum(leaves, (d) => d.screenPageViews),
      sessions: sum(leaves, (d) => d.sessions),
      averageSessionDuration:
        sum(
          leaves,
          (d) =>
            (d.averageSessionDuration /
              (Number(d.screenPageViewsPerSession) || 1)) *
            d.sessions,
        ) / sum(leaves, (d) => d.sessions),
    }),
    (d) => {
      const name = d.pageHost ? d.pageHost : d.storyID;
      const patternProd = /cp\.(.*)\.ch/;
      const patternDev = /^([a-zA-Z0-9-]+)\./;
      const devDomainRegex = /^[a-zA-Z0-9\-.]+\.dev(\/.*)?$/;
      const patternContentHub = /hub\.commercial-publishing\.ch/;
      if (name) {
        if (name.includes("www.bazonline.ch")) {
          return "bazonline";
        }

        let match = "";
        const isDev = name.match(devDomainRegex);
        if (isDev) {
          match = name.match(patternDev);
        } else {
          match = name.match(patternProd);
        }

        if (match) {
          return match[1];
        }
        if (patternContentHub.test(name)) {
          return "contentHub";
        }

        return name;
      }
      return "Unknown";
    },
  ).map(([key, value]) => ({ key, value }));
