import { Link } from "react-router-dom";
import { Toolbar, AppBar, Box } from "@mui/material";
import axios from "axios";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import classNames from "classnames";
import { useSelector } from "react-redux";
import store from "../redux/store";
import { logOut } from "../redux/actions/index";

const useStyles = makeStyles((theme) =>
  createStyles({
    appBar: {
      transition: theme.transitions.create(["margin", "width"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      marginLeft: "auto",
      marginRight: "auto",

      width: "100%",
      boxShadow: "0px 0px 0px 0px",
      borderBottom: "2px solid black",
      backgroundColor: "#223647",
    },
    appBarColored: {
      backgroundColor: "#223647",
      height: "68px",
      borderBottom: "0px",
      [theme.breakpoints.down("sm")]: {
        maxHeight: "40px",
        minHeight: "40px",
        position: "relative",
      },
    },
    appBarLogoImage: {
      maxWidth: "35px",
      [theme.breakpoints.down("sm")]: {
        maxHeight: "23px",
        maxWidth: "22px",
      },
    },
    headerLogoBox: {
      marginRight: "15px",
      display: "flex",
      [theme.breakpoints.down("sm")]: {
        maxHeight: "17px",
        maxWidth: "35px",
        marginRight: "0px",
      },
    },
    headerIconBox: {
      marginRight: "15px",
      display: "flex",
      [theme.breakpoints.down("sm")]: {
        maxHeight: "17px",
        maxWidth: "35px",
        marginRight: "0px",
      },
    },
    toolbar: {
      maxWidth: "1080px",
      margin: "auto",
      width: "100%",
      [theme.breakpoints.down("lg")]: {
        maxHeight: "40px",
        minHeight: "40px",
      },
    },
  }),
);

const Header = ({ openFn }) => {
  const classes = useStyles();
  const isLoggedIn = useSelector((state) => state.isLoggedIn);
  const isAdmin = useSelector((state) => state.isAdmin);

  const handleLogoutClick = async () => {
    store.dispatch(logOut());
    await axios.post(
      `${process.env.REACT_APP_BACKEND_BASE_URL}/auth/logouts`,
      {},
      {
        withCredentials: true,
        headers: {
          Accept: "application/json",
        },
      },
    );
    window.location.href = "#/login";
  };

  return (
    <AppBar
      position="fixed"
      className={classNames(classes.appBar, classes.appBarColored)}
    >
      <Toolbar className={classes.toolbar}>
        <Box component={Link} to="/" className={classes.headerLogoBox}>
          <img
            src="/img/20min_de_vector_negative.svg"
            alt="20min Logo"
            style={{ width: "35px" }}
            className={classes.appBarLogoImage}
          />
        </Box>
        <Box component={Link} to="/" className={classes.headerLogoBox}>
          <img
            src="/img/Tamedia-Logo.svg"
            alt="Tamedia Logo"
            style={{ width: "35px" }}
            className={classes.appBarLogoImage}
          />
        </Box>
        <Box
          style={{
            marginLeft: "auto",
            display: "flex",
            flexDirection: "row",
          }}
        >
          {isAdmin && (
            <Box
              style={{
                marginLeft: "auto",
                cursor: "pointer",
                marginRight: "15px",
              }}
              onClick={openFn}
              className={classes.headerIconBox}
            >
              <img
                src="/img/icons/users.svg"
                alt="My Account"
                className={classes.appBarLogoImage}
              />
            </Box>
          )}
          {isLoggedIn && (
            <Box
              style={{
                marginLeft: "auto",
                cursor: "pointer",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              onClick={handleLogoutClick}
              className={classes.headerIconBox}
            >
              <img
                src="/img/log-out.svg"
                alt="Log out"
                className={classes.appBarLogoImage}
              />
            </Box>
          )}
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
