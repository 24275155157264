import { Grid } from "@mui/material";
import kpiVocabulary from "../../../vocabulary/kpis";
import BigNumber from "../BigNumber";

const Summary = ({ kpis, gaSummarized }) => {
  if (!kpis) {
    return null;
  }

  return (
    <>
      <Grid
        item
        md={12}
        sm={12}
        xs={12}
        sx={{
          fontSize: {
            xs: "14px",
            md: "18px",
          },
          color: "#262627",
          lineHeight: "1.11",
          marginBottom: "6px",
        }}
      >
        Teasers and Stories
      </Grid>
      <BigNumber
        title={kpiVocabulary.screenPageViews.description}
        description={kpiVocabulary.screenPageViews.definition}
        value={kpis.screenPageViews}
        color="#223647"
      />
      <BigNumber
        title={kpiVocabulary.averageSessionDuration.description}
        description={kpiVocabulary.averageSessionDuration.definition}
        value={kpis.averageSessionDuration}
        unit="s"
        color="#223647"
      />
      <BigNumber
        title={kpiVocabulary.ctaClicks.description}
        description={kpiVocabulary.ctaClicks.definition}
        value={kpis.ctaClicks}
        color="#223647"
      />
      <BigNumber
        title={kpiVocabulary.videoPlays.description}
        description={kpiVocabulary.videoPlays.definition}
        value={
          gaSummarized.metrics && gaSummarized.metrics.campaign.videoPlays
            ? gaSummarized.metrics.campaign.videoPlays
            : kpis.videoPlays
        }
        color="#223647"
      />
      <BigNumber
        title={kpiVocabulary.outboundClicks.description}
        description={kpiVocabulary.outboundClicks.definition}
        value={kpis.outboundClicks}
        color="#223647"
      />
      <BigNumber
        title={kpiVocabulary.shares.description}
        description={kpiVocabulary.shares.definition}
        value={kpis.shares}
        color="#223647"
      />
      <BigNumber
        title={kpiVocabulary.formSubmissions.description}
        description={kpiVocabulary.formSubmissions.definition}
        value={kpis.formSubmissions}
        color="#223647"
      />
      <BigNumber
        title={kpiVocabulary.viewImpressions.description}
        description={kpiVocabulary.viewImpressions.definition}
        value={kpis.impressions}
        color="#223647"
      />
      <BigNumber
        title={kpiVocabulary.clicks.description}
        description={kpiVocabulary.clicks.definition}
        value={kpis.clicks}
        color="#223647"
      />
      <BigNumber
        title={kpiVocabulary.ctr.description}
        description={kpiVocabulary.ctr.definition}
        value={
          kpis.impressions > 0 ? (kpis.clicks / kpis.impressions) * 100 : 0
        }
        unit="%"
        decimals={3}
        color="#223647"
      />
    </>
  );
};

export default Summary;
