import { Grid, Typography } from "@mui/material";
import Story from "./Story";

const Stories = ({ stories, classes }) => (
  <Grid container spacing={2} className={classes.centeredTabs}>
    <Typography variant="h6" gutterBottom style={{ marginTop: "2em" }}>
      Social Media Posts
    </Typography>
    {stories.map((story) => (
      <div
        key={story.id}
        style={{ width: "100%", marginTop: "8px", marginBottom: "8px" }}
      >
        <Story story={story} className={classes.root} classes={classes} />
      </div>
    ))}
  </Grid>
);

export default Stories;
