import { FormControl, Grid, InputLabel } from "@mui/material";
import URLField from "./URLField";
import TargetReachDropdown from "./TargetReachDropdown";

function SocialLinks({
  socialLinks,
  removeSocialLink,
  editSocialLink,
  label,
  selectOptions,
  editTargetReach,
}) {
  return socialLinks.map((e, i) => (
    <Grid key={i} item xs={12} sm={12} md={12}>
      <FormControl fullWidth variant="outlined">
        <InputLabel
          htmlFor="outlined-adornment-password"
          style={{
            color: "#aeb5bd",
          }}
        >
          {label} {i + 1}
        </InputLabel>

        <URLField
          index={i}
          removeURL={removeSocialLink}
          url={e.socialLink}
          editURL={editSocialLink}
        />
        <TargetReachDropdown
          label="Target Reach"
          options={selectOptions}
          selection={e.targetReachId || ""}
          onChange={editTargetReach}
          index={i}
        />
      </FormControl>
    </Grid>
  ));
}

export default SocialLinks;
