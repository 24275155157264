const settings = (state = {}, action) => {
  switch (action.type) {
    case "SET_SETTINGS":
      return action.settings;
    case "LOG_OUT":
      return [];
    default:
      return state;
  }
};

export default settings;
