import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import axios from "axios";
import moment from "moment";
import {
  Grid,
  DialogTitle,
  Button,
  TextField,
  IconButton,
  FormControl,
  InputLabel,
  Box,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import store from "../redux/store";
import { logOut, setErrorMessage } from "../redux/actions/index";
import LoadingSpinner from "./LoadingSpinner";
import SocialLinks from "./SocialLinks";
import URLField from "./URLField";

const URLFields = ({ urls, removeURL, editURL, label }) => {
  if (!Array.isArray(urls)) {
    return null;
  }

  return urls.map((e, i) => (
    <Grid key={i} item xs={12} sm={12} md={12}>
      <FormControl fullWidth variant="outlined">
        <InputLabel htmlFor="outlined-adornment-password">
          {label} {i + 1}
        </InputLabel>
        <URLField index={i} removeURL={removeURL} url={e} editURL={editURL} />
      </FormControl>
    </Grid>
  ));
};

const EditProject = ({ project, handleCloseDialog, refreshReport }) => {
  const navigate = useNavigate();
  const isAdmin = useSelector((state) => state.isAdmin);
  const settings = useSelector((state) => state.settings);
  const [projectData, setProjectData] = useState(project);
  const [includesSocialMediaPosts, setIncludesSocialMediaPosts] = useState(
    project && project.socialLinks && project.socialLinks.length > 0,
  );
  const [includesLongforms, setIncludesLongforms] = useState(
    project && project.longforms && project.longforms.length > 0,
  );
  const [isLoading, setIsLoading] = useState(false);

  const handleChange = (event) => {
    const edit = { [event.target.name]: event.target.value };
    setProjectData({ ...projectData, ...edit });
  };

  const handleDateChange = (fieldName) => (date) => {
    if (date !== "Invalid Date") {
      const edit = { [fieldName]: date };
      setProjectData({ ...projectData, ...edit });
    }
  };

  const handleLinkoutCampaign = (value) => {
    const checkValue = value.target.checked;
    const edit = { isLinkoutCampaign: checkValue };
    setProjectData({ ...projectData, ...edit });
  };

  const handleContentHubCampaign = (value) => {
    const checkValue = value.target.checked;
    const edit = { isContentHub: checkValue };
    setProjectData({ ...projectData, ...edit });
  };

  const handleIncludesSocialMediaPostsChange = (value) => {
    const checked = value.target.checked;
    setIncludesSocialMediaPosts(checked);
    const socialLinks = checked
      ? [
          {
            socialLink: "",
            targetReachId: null,
          },
        ]
      : [{}];
    const edit = { socialLinks };
    setProjectData({ ...projectData, ...edit });
  };

  const handleIncludesLongformsChange = (value) => {
    const checked = value.target.checked;
    setIncludesLongforms(checked);
    const longforms = checked ? [""] : [];
    const edit = { longforms };
    setProjectData({ ...projectData, ...edit });
  };

  const handleDailyDealCampaign = (value) => {
    const checkValue = value.target.checked;
    const edit = { isDailyDeal: checkValue };
    setProjectData({ ...projectData, ...edit });
  };

  const addUrl = () => {
    const edit = { urls: projectData.urls || [] };
    edit.urls.push("");
    setProjectData({ ...projectData, ...edit });
  };

  const removeURL = (key) => () => {
    const edit = { urls: projectData.urls || [] };

    edit.urls.splice(key, 1);

    setProjectData({ ...projectData, ...edit });
  };

  const editURL = (key) => (value) => {
    const edit = { urls: projectData.urls || [] };
    edit.urls[key] = value;
    setProjectData({ ...projectData, ...edit });
  };

  const addSocialLink = () => {
    const edit = { socialLinks: projectData.socialLinks || [] };
    edit.socialLinks.push({
      socialLink: "",
      targetReachId: "",
    });
    setProjectData({ ...projectData, ...edit });
  };

  const removeSocialLink = (key) => () => {
    const edit = { socialLinks: projectData.socialLinks || [{}] };
    edit.socialLinks.splice(key, 1);
    setProjectData({ ...projectData, ...edit });
  };

  const editSocialLink = (key) => (value) => {
    const edit = { socialLinks: projectData.socialLinks || [{}] };
    edit.socialLinks[key].socialLink = value;
    setProjectData({ ...projectData, ...edit });
  };

  const editTargetReach = (key) => (value) => {
    const edit = { socialLinks: projectData.socialLinks || [{}] };
    edit.socialLinks[key].targetReachId = value || null;
    setProjectData({ ...projectData, ...edit });
  };

  const addLongform = () => {
    const edit = { longforms: projectData.longforms || [] };
    edit.longforms.push("");
    setProjectData({ ...projectData, ...edit });
  };

  const removeLongform = (key) => () => {
    const edit = { longforms: projectData.longforms || [] };
    edit.longforms.splice(key, 1);
    setProjectData({ ...projectData, ...edit });
  };

  const editLongform = (key) => (value) => {
    const edit = { longforms: projectData.longforms || [] };
    edit.longforms[key] = value;
    setProjectData({ ...projectData, ...edit });
  };

  const handleSaveClick = async () => {
    const data = projectData;
    data.daterangeFrom = moment(projectData.daterangeFrom).format("YYYY-MM-DD");
    data.daterangeTo = moment(projectData.daterangeTo).format("YYYY-MM-DD");

    store.dispatch(setErrorMessage(null));
    setIsLoading(true);

    try {
      await axios.patch(
        `${process.env.REACT_APP_BACKEND_BASE_URL}/v1/projects/${projectData._id}`,
        data,
        {
          withCredentials: true,
          headers: {
            Accept: "application/json",
          },
        },
      );

      handleCloseDialog();
      if (refreshReport) {
        refreshReport(projectData._id, { useProjectDateRange: true });
      }
    } catch (err) {
      let msg = "Error saving project";
      if (err.response) {
        if (err.response.status === 401) {
          store.dispatch(logOut());
          navigate("/login");
          return;
        }
        msg += `: ${err.response.data}`;
      }
      store.dispatch(setErrorMessage(msg));
    } finally {
      setIsLoading(false);
    }
  };

  const handleDeleteClick = async () => {
    store.dispatch(setErrorMessage(null));
    setIsLoading(true);

    try {
      await axios.delete(
        `${process.env.REACT_APP_BACKEND_BASE_URL}/v1/projects/${projectData._id}`,
        {
          withCredentials: true,
          headers: {
            Accept: "application/json",
          },
        },
      );

      handleCloseDialog();
      navigate("/");
    } catch (err) {
      let msg = "Error deleting project";
      if (err.response) {
        if (err.response.status === 401) {
          store.dispatch(logOut());
          navigate("/login");
          return;
        }
        msg += `: ${err.response.data}`;
      }
      store.dispatch(setErrorMessage(msg));
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Grid container spacing={2}>
      <DialogTitle>Edit Project</DialogTitle>
      <Grid item xs={12} sm={12} md={12}>
        {projectData && (
          <Grid container spacing={2}>
            {projectData &&
              projectData.projectType &&
              projectData.projectType === "abnumberChoice" && (
                <Grid item xs={12} sm={12} md={12}>
                  <TextField
                    name="abnumber"
                    variant="outlined"
                    label="Order number"
                    value={projectData.abnumber || ""}
                    fullWidth
                    onChange={handleChange}
                    InputLabelProps={{
                      style: {
                        color: "#5e686c",
                      },
                    }}
                  />
                </Grid>
              )}

            {projectData && (
              <>
                <Grid item xs={12} sm={12} md={12}>
                  <TextField
                    name="client"
                    variant="outlined"
                    label="Customer"
                    value={projectData.client || ""}
                    fullWidth
                    onChange={handleChange}
                    InputLabelProps={{
                      style: {
                        color: "#5e686c",
                      },
                    }}
                  />
                </Grid>
                {projectData.projectType &&
                  projectData.projectType !== "abnumberChoice" && (
                    <Grid item xs={12} sm={12} md={12}>
                      <TextField
                        name="name"
                        variant="outlined"
                        label="Campaign"
                        value={projectData.name || ""}
                        fullWidth
                        onChange={handleChange}
                        InputLabelProps={{
                          style: {
                            color: "#5e686c",
                          },
                        }}
                      />
                    </Grid>
                  )}
              </>
            )}

            <Grid item xs={6} sm={6} md={6}>
              <DatePicker
                label="Start date"
                value={projectData.daterangeFrom}
                onChange={handleDateChange("daterangeFrom")}
                renderInput={(params) => <TextField fullWidth {...params} />}
                components={{
                  OpenPickerIcon: () => (
                    <img
                      src="/img/icons/calendar-grey.svg"
                      alt="Pick from date"
                      style={{ width: "28px", height: "28px" }}
                    />
                  ),
                }}
                minDate={
                  isAdmin ? new Date("2020-01-01") : new Date("2021-01-01")
                }
              />
            </Grid>

            <Grid item xs={6} sm={6} md={6}>
              <DatePicker
                label="End date"
                value={projectData.daterangeTo}
                onChange={handleDateChange("daterangeTo")}
                renderInput={(params) => <TextField fullWidth {...params} />}
                components={{
                  OpenPickerIcon: () => (
                    <img
                      src="/img/icons/calendar-grey.svg"
                      alt="Pick from date"
                      style={{ width: "28px", height: "28px" }}
                    />
                  ),
                }}
                minDate={
                  isAdmin ? new Date("2020-01-01") : new Date("2021-01-01")
                }
              />
            </Grid>
            {projectData && projectData.urls && (
              <URLFields
                urls={projectData.urls}
                removeURL={removeURL}
                editURL={editURL}
                label="Story Link"
              />
            )}
            {projectData &&
              projectData.projectType &&
              projectData.projectType === "storyLinkChoice" && (
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  style={{ display: "flex", justifyContent: "flex-end" }}
                >
                  <IconButton onClick={addUrl} size="large">
                    <Box style={{ display: "flex" }}>
                      <img
                        src="/img/icons/plus-circle.svg"
                        alt="Add URL"
                        style={{ width: "23.6px", height: "23.6px" }}
                      />
                    </Box>
                  </IconButton>
                </Grid>
              )}

            {projectData &&
              projectData.projectType &&
              projectData.projectType === "abnumberChoice" && (
                <>
                  <Grid item xs={12} sm={12} md={6}>
                    <FormControlLabel
                      value="isLinkout"
                      label="Linkout Campaign"
                      labelPlacement="end"
                      control={
                        <Checkbox
                          onChange={handleLinkoutCampaign}
                          checked={projectData.isLinkoutCampaign}
                        />
                      }
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6}>
                    <FormControlLabel
                      value="isContentHub"
                      label="Content Hub"
                      labelPlacement="end"
                      control={
                        <Checkbox
                          onChange={handleContentHubCampaign}
                          checked={projectData.isContentHub}
                        />
                      }
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6}>
                    <FormControlLabel
                      value="isDailyDeal"
                      label="Daily Deal"
                      labelPlacement="end"
                      control={
                        <Checkbox
                          onChange={handleDailyDealCampaign}
                          checked={projectData.isDailyDeal}
                        />
                      }
                    />
                  </Grid>
                </>
              )}
            {projectData &&
              projectData.projectType &&
              projectData.projectType === "abnumberChoice" && (
                <Grid item xs={12} sm={12} md={6}>
                  <FormControlLabel
                    label="Includes Social Media Posts"
                    control={
                      <Checkbox
                        checked={includesSocialMediaPosts}
                        onChange={handleIncludesSocialMediaPostsChange}
                      />
                    }
                  />
                </Grid>
              )}
            {projectData &&
              projectData.projectType &&
              projectData.projectType === "abnumberChoice" && (
                <Grid item xs={12} sm={12} md={6}>
                  <FormControlLabel
                    label="Includes Longforms"
                    control={
                      <Checkbox
                        checked={includesLongforms}
                        onChange={handleIncludesLongformsChange}
                      />
                    }
                  />
                </Grid>
              )}
            {projectData &&
              projectData.socialLinks &&
              includesSocialMediaPosts && (
                <>
                  <SocialLinks
                    socialLinks={projectData.socialLinks}
                    removeSocialLink={removeSocialLink}
                    editSocialLink={editSocialLink}
                    label="Social Media Post"
                    selectOptions={settings.soLinkTargetReachConfigs}
                    editTargetReach={editTargetReach}
                  />
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    style={{ display: "flex", justifyContent: "flex-end" }}
                  >
                    <IconButton onClick={addSocialLink} size="large">
                      <Box style={{ display: "flex" }}>
                        <img
                          src="/img/icons/plus-circle.svg"
                          alt="Add Social Media Post"
                          style={{ width: "23.6px", height: "23.6px" }}
                        />
                      </Box>
                    </IconButton>
                  </Grid>
                </>
              )}
            {projectData && projectData.longforms && includesLongforms && (
              <>
                <URLFields
                  urls={projectData.longforms}
                  removeURL={removeLongform}
                  editURL={editLongform}
                  label="Longform URL"
                />
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  style={{ display: "flex", justifyContent: "flex-end" }}
                >
                  <IconButton onClick={addLongform} size="large">
                    <Box style={{ display: "flex" }}>
                      <img
                        src="/img/icons/plus-circle.svg"
                        alt="Add Longform"
                        style={{ width: "23.6px", height: "23.6px" }}
                      />
                    </Box>
                  </IconButton>
                </Grid>
              </>
            )}

            <LoadingSpinner isLoading={isLoading} />

            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              {isAdmin && (
                <Box component="span" m={1}>
                  <Button
                    variant="outlined"
                    onClick={handleDeleteClick}
                    style={{
                      textTransform: "none",
                      color: "white",
                      minHeight: "47px",
                      minWidth: "163px",
                      backgroundColor: "#223647",
                      "&:hover": {
                        backgroundColor: "rgba(34, 54, 71, 0.76)",
                      },
                    }}
                  >
                    Delete
                  </Button>
                </Box>
              )}
              <Box component="span" m={1}>
                <Button
                  variant="outlined"
                  onClick={handleSaveClick}
                  style={{
                    textTransform: "none",
                    color: "white",
                    minHeight: "47px",
                    minWidth: "163px",
                    backgroundColor: "#223647",
                    "&:hover": {
                      backgroundColor: "rgba(34, 54, 71, 0.76)",
                    },
                  }}
                >
                  Save
                </Button>
              </Box>
            </Grid>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default EditProject;
