import { Box } from "@mui/material";
import Spinner from "react-spinkit";

const LoadingSpinner = ({ isLoading }) => (
  <Box
    sx={{
      width: "100%",
      height: "70px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    }}
  >
    {isLoading && <Spinner name="pacman" color="#bcbcbc" />}
  </Box>
);

export default LoadingSpinner;
