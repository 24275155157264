const kpiVocabulary = {
  screenPageViews: {
    description: "Pageviews",
    definition: "Amount of clicks on a landing page",
  },
  averageSessionDuration: {
    description: "Avg Time on Page",
    definition: "Average amount of time spent on a landing page",
  },
  ctaClicks: {
    description: "CTA Clicks",
    definition: "Amount of clicks on a call to action button",
  },
  shares: {
    description: "Shares",
    definition: "Amount of shares of a landing page",
  },
  videoPlays: {
    description: "Video Plays",
    definition: "Amount of clicks on videos on a landing page",
  },
  outboundClicks: {
    description: "Outbound Clicks",
    definition:
      "Amount of outbound clicks on hyperlinks, pictures and logos (excl. CTA clicks)",
  },
  viewImpressions: {
    description: "Impressions",
    definition: "Amount of displays of a teaser on a media site",
  },
  clicks: {
    description: "Clicks",
    definition: "Amount of clicks on teasers",
  },
  ctr: {
    description: "CTR",
    definition: "Average click through rate of a teaser",
  },
  scrollDepth: {
    description: "Avg Scroll Depth",
    definition: "Average of users' scrolls on a landing page",
  },
  formSubmissions: {
    description: "Form Submissions",
    definition: "The total number of times users have submitted forms",
  },
};

export default kpiVocabulary;
