import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Alert, Collapse, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const ErrorMessage = () => {
  const message = useSelector((state) => state.errorMessage);
  const [alertIsOpen, setAlertIsOpen] = useState(true);

  useEffect(() => {
    if (message) {
      setAlertIsOpen(true);
    }
  }, [message]);

  return (
    <Collapse in={message && alertIsOpen}>
      <Alert
        severity="error"
        variant="filled"
        style={{ position: "absolute", top: "100px", right: "30px" }}
        action={
          <IconButton
            aria-label="close"
            color="inherit"
            size="small"
            onClick={() => {
              setAlertIsOpen(false);
            }}
          >
            <CloseIcon fontSize="inherit" />
          </IconButton>
        }
      >
        {message}
      </Alert>
    </Collapse>
  );
};

export default ErrorMessage;
